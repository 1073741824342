






import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class SearchSelectItem extends Vue {
  @Prop({ required: true })
  public readonly item!: SearchSelectItem;
}
