


























































































import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import ConfirmBulkShipmentCommand from "@/modules/bulk-project/components/ConfirmBulkShipmentCommand.vue";
import StartSampleProductionCommand from "@/modules/bulk-project/components/StartSampleProductionCommand.vue";
import UploadQaAssetsCommand from "@/modules/quality-inspection/components/UploadQaAssetsCommand.vue";
import QualityInspectionRejectCommand from "@/modules/quality-inspection/components/RejectCommand.vue";
import QualityInspectionApproveCommand from "@/modules/quality-inspection/components/ApproveCommand.vue";
import ExtraCustomerPreviewCommand from "@/modules/customer-preview/components/ExtraCustomerPreviewCommand.vue";
import RushBonusesManageCommands from "@/modules/plushie-priority/components/RushBonusesManageCommands.vue";
import SendToCustomerCommand from "@/modules/production-process/components/SendToCustomerCommand.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import ProductionProcessStore from "@/modules/production-process/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { CommandCode } from "@/modules/production-process/command-code";
import { Resource } from "@/modules/account/resource";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";

import ReviewApproveCommand from "./ReviewApprove/Command.vue";
import ReassignFactoryCommand from "./ReassignFactoryCommand.vue";
import BasicCommand from "./BasicCommand.vue";
import CancelPlushieCommand from "./CancelPlushieCommand.vue";

import Command from "../command.model";

@Component({
  components: {
    BasicCommand,
    CancelPlushieCommand,
    ReassignFactoryCommand,
    ReviewApproveCommand,
    QualityInspectionApproveCommand,
    QualityInspectionRejectCommand,
    UploadQaAssetsCommand,
    ExtraCustomerPreviewCommand,
    RushBonusesManageCommands,
    SendToCustomerCommand,
    StartSampleProductionCommand,
    ConfirmBulkShipmentCommand,
  },
})
export default class ProductionProcessCommandsCard extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fPlushieDataStore: PlushieStore;
  private fProductionProcessDataStore: ProductionProcessStore;

  get availableCommands(): Set<CommandCode> {
    return this.fProductionProcessDataStore.getAvailableCommandsForPlushieId(
      this.plushieId
    );
  }

  get basicCommands(): Command[] {
    return this.fProductionProcessDataStore.basicCommands;
  }

  get isDisabled(): boolean {
    return this.fProductionProcessDataStore.isPlushieProcessing(this.plushieId);
  }

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fProductionProcessDataStore = getModule(
      ProductionProcessStore,
      dataStore
    );
  }

  get availableBasicCommandsCodes(): CommandCode[] {
    const availableCommandsCodes: CommandCode[] = [];

    for (const basicCommand of this.basicCommands) {
      if (!this.availableCommands.has(basicCommand.code)) {
        continue;
      }

      availableCommandsCodes.push(basicCommand.code);
    }

    return availableCommandsCodes;
  }

  get isReassignFactoryAvailable(): boolean {
    return this.availableCommands.has(CommandCode.ReassignFactory);
  }

  get isReviewApproveAvailable(): boolean {
    const plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);

    if (!plushie) {
      return false;
    }

    if (plushie.status !== PlushieStatusValue.REVIEW) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.PLUSHIE_APPROVE_REVIEW);
  }

  get isRushBonusesManageAvailable(): boolean {
    const plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);

    if (!plushie) {
      return false;
    }

    if (
      ![
        PlushieStatusValue.REVIEW,
        PlushieStatusValue.READY_FOR_PRODUCTION,
        PlushieStatusValue.IN_DESIGN,
        PlushieStatusValue.IN_PRODUCTION,
      ].includes(plushie.status)
    ) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.PLUSHIE_PRIORITY_BONUS_MANAGE
    );
  }

  get isQaApproveAvailable(): boolean {
    const qaApproveCommands = [
      CommandCode.ApproveQualityInspection,
      CommandCode.ApproveBulkInspection,
      CommandCode.ApprovePpsInspection,
    ];

    return qaApproveCommands.some((command) =>
      this.availableCommands.has(command)
    );
  }

  get isQaRejectAvailable(): boolean {
    const qaRejectCommands = [
      CommandCode.RejectQualityInspection,
      CommandCode.RejectBulkInspection,
      CommandCode.RejectPpsInspection,
    ];

    return qaRejectCommands.some((command) =>
      this.availableCommands.has(command)
    );
  }

  get isUploadQaAssetsAvailable(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    const plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);
    if (!plushie) {
      return false;
    }

    if (!user.hasPermissionForResource(Resource.QA_ASSETS_CREATE)) {
      return false;
    }

    if (
      [
        PlushieStatusValue.QUALITY_INSPECTION,
        PlushieStatusValue.PPS_INSPECTION,
        PlushieStatusValue.BULK_INSPECTION,
      ].includes(plushie.status)
    ) {
      return true;
    }

    const moveToQaCommands = [
      CommandCode.MoveToQualityInspection,
      CommandCode.MoveToBulkInspection,
      CommandCode.MoveToPpsInspection,
    ];

    return (
      moveToQaCommands.some((command) => this.availableCommands.has(command)) &&
      user.hasPermissionForResource(Resource.QUALITY_INSPECTION_INIT)
    );
  }

  get isExtraCustomerPreviewManagingAvailable(): boolean {
    const plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);

    if (!plushie) {
      return false;
    }

    if (
      ![
        PlushieStatusValue.REVIEW,
        PlushieStatusValue.READY_FOR_PRODUCTION,
        PlushieStatusValue.IN_DESIGN,
        PlushieStatusValue.IN_PRODUCTION,
      ].includes(plushie.status)
    ) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.CUSTOMER_PREVIEW_COUNT_MANAGE
    );
  }

  get isSendToCustomerAvailable(): boolean {
    const plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);

    if (!plushie) {
      return false;
    }

    if (
      ![
        PlushieStatusValue.AWAITING_DIRECT_SHIPMENT,
        PlushieStatusValue.READY_FOR_BULK_SHIPMENT,
      ].includes(plushie.status)
    ) {
      return false;
    }

    return this.availableCommands.has(CommandCode.SendToCustomer);
  }

  get isStartSampleProductionAvailable(): boolean {
    const plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);

    if (!plushie) {
      return false;
    }

    if (plushie.status !== PlushieStatusValue.PENDING_BULK_PRODUCTION) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.PLUSHIE_START_SAMPLE_PRODUCTION
    );
  }

  get isConfirmBulkShipmentCommandAvailable(): boolean {
    return this.availableCommands.has(CommandCode.ConfirmBulkShipment);
  }

  get isCancelPlushieCommandAvailable(): boolean {
    const plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);

    if (!plushie) {
      return false;
    }

    const product = this.fPlushieDataStore.getProductById(plushie.product);

    if (!product || !product.isBatch) {
      return false;
    }

    return this.availableCommands.has(CommandCode.CancelPlushie);
  }

  private async getPlushieData(plushieId: string) {
    await Promise.all([
      this.fProductionProcessDataStore.loadAvailableCommandsForPlushieId({
        plushieId,
      }),
      this.fPlushieDataStore.loadPlushieById({ id: plushieId }),
    ]);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    await this.getPlushieData(this.plushieId);
  }
}
