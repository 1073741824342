
































































































































































































































































































































































































import { Component, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import jQuery from "jquery";
import { RawLocation } from "vue-router";

import { Dictionary } from "@/lib/Dictionary.type";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";
import UiConfigurationStore from "@/modules/ui-configuration/store";
import dataStore from "@/store";
import { ProductAllocationSlotTypeValue } from "@/modules/factory/product-allication-slot-type.value";

import FactoryManagementMenu from "./FactoryManagementMenu.vue";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Foundation = require("foundation-sites");

interface RouteItem {
  label: string;
  route: {
    name: string;
    query: Dictionary<string>;
  };
  roles: string[];
}

@Component({
  components: {
    FactoryManagementMenu,
  },
  metaInfo: {},
})
export default class MainMenu extends Vue {
  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("window")
  private fWindow!: Window;

  private fBatchesListRoutes: RouteItem[] = [
    {
      label: "Pending Bulk Production",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.PENDING_BULK_PRODUCTION },
      },
      roles: [AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE],
    },
    {
      label: "In Bulk Design",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.IN_BULK_DESIGN },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "PPS Inspection",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.PPS_INSPECTION },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "PPS Inspection\n(my batches)",
      route: {
        name: "PlushiesListPage",
        query: {
          status: PlushieStatusValue.PPS_INSPECTION,
          responsibleUser: this.currentUserId,
        },
      },
      roles: [AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE],
    },
    {
      label: "PPS Preview",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.PPS_PREVIEW },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "In Bulk Production",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.IN_BULK_PRODUCTION },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "Bulk Inspection",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.BULK_INSPECTION },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "Bulk Inspection\n(my batches)",
      route: {
        name: "PlushiesListPage",
        query: {
          status: PlushieStatusValue.BULK_INSPECTION,
          responsibleUser: this.currentUserId,
        },
      },
      roles: [AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE],
    },
    {
      label: "Bulk Preview",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.BULK_PREVIEW },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "Pending Bulk Shipment",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.PENDING_BULK_SHIPMENT },
      },
      roles: [AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE],
    },
    {
      label: "Ready For Bulk Shipment",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.READY_FOR_BULK_SHIPMENT },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "Shipped To Customer",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.SHIPPED_TO_CUSTOMER },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
  ];

  private fMenu?: FoundationSites.AccordionMenu;

  private fPlushieListRoutes: RouteItem[] = [
    {
      label: "On Hold",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.ON_HOLD },
      },
      roles: [AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE],
    },
    {
      label: "Review",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.REVIEW },
      },
      roles: [AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE],
    },
    {
      label: "Ready for Production",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.READY_FOR_PRODUCTION },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "In Design",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.IN_DESIGN },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "In Production",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.IN_PRODUCTION },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "Rework",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.REWORK },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "Quality Inspection\n(my plushies)",
      route: {
        name: "PlushiesListPage",
        query: {
          status: PlushieStatusValue.QUALITY_INSPECTION,
          reviewer: this.currentUserId,
        },
      },
      roles: [AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE],
    },
    {
      label: "Quality Inspection",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.QUALITY_INSPECTION },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "Customer Preview",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.CUSTOMER_PREVIEW },
      },
      roles: [AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE],
    },
    {
      label: "Customer Preview Approved",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.CUSTOMER_PREVIEW_APPROVED },
      },
      roles: [AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE],
    },
    {
      label: "Ready For Shipment",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.READY_FOR_SHIPMENT },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "Awaiting Direct Shipment",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.AWAITING_DIRECT_SHIPMENT },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
    {
      label: "Shipped to Main Facility",
      route: {
        name: "PlushiesListPage",
        query: { status: PlushieStatusValue.SHIPPED_TO_MAIN_FACILITY },
      },
      roles: [
        AuthenticatedUser.ROLE_ADMIN,
        AuthenticatedUser.ROLE_STORE,
        AuthenticatedUser.ROLE_FACTORY,
      ],
    },
  ];

  private fUiConfigurationStore: UiConfigurationStore;

  get batchesListRoutes(): RouteItem[] {
    return this.fBatchesListRoutes.filter((item) => {
      return this.isRouteAvailable(item);
    });
  }

  get canViewBulkProduction(): boolean {
    return (
      this.batchesListRoutes.length > 0 || this.canViewBulkProjectsListPage
    );
  }

  get canViewBulkProjectsListPage(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (user.role === AuthenticatedUser.ROLE_FACTORY) {
      return false;
    }

    return (
      user.hasPermissionForResource(Resource.BULK_PROJECT_MANAGE) ||
      user.hasPermissionForResource(Resource.BULK_PROJECT_VIEW)
    );
  }

  get canViewDashboard(): boolean {
    return true;
  }

  get canViewPlushies(): boolean {
    return (
      this.plushieListRoutes.length > 0 ||
      this.canViewBulkShipments ||
      this.canBulkUploadQAAssets ||
      this.canSearchTags
    );
  }

  get canViewBulkShipments(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.BULK_SHIPMENTS_READ);
  }

  get canBulkUploadQAAssets(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      user.hasPermissionForResource(
        Resource.PLUSHIE_MOVE_TO_QUALITY_INSPECTION
      ) &&
      user.hasPermissionForResource(Resource.QA_ASSETS_CREATE) &&
      user.hasPermissionForResource(Resource.QUALITY_INSPECTION_INIT)
    );
  }

  get canSearchTags(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (
      ![AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE].includes(
        user.role
      )
    ) {
      return false;
    }

    return user.hasPermissionForResource(Resource.READ_PLUSHIE_TAGS);
  }

  get canViewExtraPaymentsReport(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_EXTRA_PAYMENTS_READ
    );
  }

  get canViewProductionEstimateReport(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      user.hasPermissionForResource(
        Resource.REPORTING_PRODUCTION_ESTIMATE_VIEW
      ) ||
      user.hasPermissionForResource(
        Resource.REPORTING_PRODUCTION_ESTIMATE_MANAGE
      )
    );
  }

  get canViewSnapshotReport(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.REPORTING_SNAPSHOT_STATS_VIEW
    );
  }

  get canViewReports(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return [
      AuthenticatedUser.ROLE_ADMIN,
      AuthenticatedUser.ROLE_STORE,
    ].includes(user.role);
  }

  get canViewUseOfRejectionReasonReport(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.QA_DECISION_REJECTION_REASONS_READ
    );
  }

  get canViewHolidayCutoffReport(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.HOLIDAY_PERIOD_REPORT_VIEW);
  }

  get canEditFactoriesBillingInfo(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_BILLING_INFO_MANAGE);
  }

  get canViewFactoriesManagement(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (user.role === AuthenticatedUser.ROLE_FACTORY) {
      return false;
    }

    return (
      this.canViewFactoriesList ||
      this.canViewProductAllocation ||
      this.canViewFactoryInvoices ||
      this.canViewHolidayPeriods ||
      this.canViewProductionTimeNorms ||
      this.canViewPriceConfiguration ||
      this.canViewPenaltyRules ||
      this.canEditFactoriesBillingInfo ||
      this.canViewDirectShipmentIntentionRulesList
    );
  }

  get canViewDirectShipmentIntentionRulesList(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.DIRECT_SHIPMENT_INTENTION_RULE_VIEW
    );
  }

  get canViewFactoriesList(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_VIEW);
  }

  get canViewProductAllocation(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.PRODUCT_ALLOCATION_RULES_VIEW
    );
  }

  get canViewFactoryInvoices(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.INVOICE_READ);
  }

  get canViewHolidayPeriods(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.HOLIDAY_PERIOD_VIEW);
  }

  get canViewProductionTimeNorms(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      user.hasPermissionForResource(Resource.FACTORY_VIEW) &&
      user.hasPermissionForResource(Resource.PRODUCTION_TIME_NORM_VIEW)
    );
  }

  get canViewPriceConfiguration(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      user.hasPermissionForResource(Resource.FACTORY_VIEW) &&
      user.hasPermissionForResource(Resource.FACTORY_INVOICE_PRICES_READ)
    );
  }

  get canViewPenaltyRules(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      user.hasPermissionForResource(Resource.FACTORY_VIEW) &&
      user.hasPermissionForResource(Resource.FACTORY_INVOICE_PENALTIES_READ)
    );
  }

  get canViewRestrictedModeConfiguration(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      user.hasPermissionForResource(Resource.FACTORY_VIEW) &&
      user.hasPermissionForResource(
        Resource.FACTORY_RESTRICTED_MODE_CONFIG_VIEW
      )
    );
  }

  get canViewStrictCapacityModeConfiguration(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      user.hasPermissionForResource(Resource.FACTORY_VIEW) &&
      user.hasPermissionForResource(Resource.FACTORY_STRICT_CAPACITY_MODE_VIEW)
    );
  }

  get canViewUpgradeAllocation(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.PRODUCT_ALLOCATION_RULES_VIEW
    );
  }

  get canViewConfiguration(): boolean {
    return (
      this.canViewBarcodeLabel ||
      this.canViewBodyParts ||
      this.canViewPlushieAlerts ||
      this.canViewQaRejectionReasons ||
      this.canViewQuestionTemplates ||
      this.canManageRemotePrinting ||
      this.canEditStoreBillingInfo ||
      this.canViewEmailLayoutsAndTemplates ||
      this.canViewUpgradesListPage
    );
  }

  get canViewBarcodeLabel(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.LABEL_HEADER_MANAGE);
  }

  get canViewBodyParts(): boolean {
    return false;
  }

  get canViewEmailLayoutsAndTemplates(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.TEMPLATES_VIEW);
  }

  get canViewFutureTurnaroundReport(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.REPORTING_FUTURE_TURNAROUND_STATS_VIEW
    );
  }

  get canViewPlushieAlerts(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.PLUSHIE_ALERTS_MANAGE);
  }

  get canViewQaRejectionReasons(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.REJECTION_REASONS_READ);
  }

  get canViewQualityInspectionReport(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.REPORTING_QUALITY_INSPECTION_STATS_VIEW
    );
  }

  get canViewQuestionTemplates(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.QUESTION_TEMPLATE_VIEW);
  }

  get canViewUpgradesListPage(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.PLUSHIE_UPGRADES_VIEW);
  }

  get canEditStoreBillingInfo(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (
      ![AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE].includes(
        user.role
      )
    ) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_SETTINGS_MANAGE
    );
  }

  get canManageRemotePrinting(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (user.role !== AuthenticatedUser.ROLE_FACTORY) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.REMOTE_PRINTING_CREDENTIALS_MANAGE
    );
  }

  get canViewThroughputReport(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.REPORTING_THROUGHPUT_STATS_VIEW
    );
  }

  get canViewPermissions(): boolean {
    return this.canViewOrganizations || this.canViewRoles || this.canViewUsers;
  }

  get canViewOrganizations(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.MANAGE_ORGANIZATIONS);
  }

  get canViewReviewerReport(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.REPORTING_REVIEWER_STATS_VIEW
    );
  }

  get canViewRoles(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.role === AuthenticatedUser.ROLE_ADMIN;
  }

  get canViewUsers(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.MANAGE_USERS);
  }

  get currentUserId(): string {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return "";
    }

    return user.id;
  }

  get isCompact(): boolean {
    return this.fUiConfigurationStore.isMainMenuCompact;
  }

  set isCompact(value: boolean) {
    this.fUiConfigurationStore.setMainMenuCompact(value);

    if (this.isCompact && this.fMenu) {
      this.fMenu.hideAll();
    }
  }

  get plushieListRoutes(): RouteItem[] {
    return this.fPlushieListRoutes.filter((item) =>
      this.isRouteAvailable(item)
    );
  }

  get designersAllocationConfigPageRoute(): RawLocation {
    return {
      name: "ProductAllocationEditPage",
      query: { slotType: ProductAllocationSlotTypeValue.DESIGN },
    };
  }

  get productionAllocationConfigPageRoute(): RawLocation {
    return {
      name: "ProductAllocationEditPage",
      query: { slotType: ProductAllocationSlotTypeValue.PRODUCTION },
    };
  }

  public constructor() {
    super();

    this.fUiConfigurationStore = getModule(UiConfigurationStore, dataStore);
  }

  public toggleCompactMode(): void {
    this.isCompact = !this.isCompact;
  }

  public onSubMenuStateUpdate(): void {
    Foundation.Foundation.reInit("accordion-menu");
  }

  protected async mounted(): Promise<void> {
    const el = this.$refs.mainMenu as HTMLElement;

    this.fMenu = new Foundation.AccordionMenu(jQuery(el));

    this.fWindow.addEventListener("resize", this.fResizeHandler);

    this.forceSize();
  }

  protected beforeDestroy(): void {
    this.fWindow.removeEventListener("resize", this.fResizeHandler);

    if (!this.fMenu) {
      return;
    }

    this.fMenu.destroy();
  }

  private isRouteAvailable(routeItem: RouteItem): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (!routeItem.roles.includes(user.role)) {
      return false;
    }

    return true;
  }

  private forceSize() {
    if (document.documentElement.clientWidth <= 1024) {
      this.isCompact = true;
    }
  }

  private fResizeHandler = () => this.forceSize();
}
