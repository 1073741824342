



























































import Vue from "vue";
import { Component, Inject } from "vue-property-decorator";
import { RowClickEventData, ServerResponse } from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import { Dictionary } from "@/lib/Dictionary.type";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import rootStore from "@/store";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";

import PlushieStore from "../../store";
import UpgradeRepository from "../../upgrade.repository";

interface TableRow {
  id: string;
  code: string;
  title: string;
  shortName: string;
  displayToFactory: boolean;
  isManageable: boolean;
}

@Component({
  metaInfo() {
    return {
      title: "Upgrades List",
    };
  },
  components: {
    LoadingSpinner,
  },
})
export default class UpgradesListPage extends mixins(GeneralListPageMixin) {
  @Inject("AuthenticatedUserProvider")
  private fAuthenticatedUserProvider!: AuthenticatedUserProvider;

  @Inject("UpgradeRepository")
  private fUpgradeRepository!: UpgradeRepository;

  private fRemovingUpgrade: Dictionary<boolean> = {};

  private fPlushieStore: PlushieStore;

  get canManageUpgrades(): boolean {
    const user = this.fAuthenticatedUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.PLUSHIE_UPGRADES_MANAGE);
  }

  public constructor() {
    super();

    this.fPlushieStore = getModule(PlushieStore, rootStore);

    this.fColumns = [
      {
        name: "code",
        header: "Code",
        isSortable: true,
        isFilterable: true,
        displayConstraint: "min_desktop",
        cssClass: "_code-column",
      },
      {
        name: "title",
        header: "Title",
        isSortable: true,
        isFilterable: true,
        cssClass: "_title-column",
      },
      {
        name: "shortName",
        header: "Short name",
        isSortable: true,
      },
      {
        name: "displayToFactory",
        header: "Display to factory",
        isFilterFromList: true,
        isFilterable: true,
        displayConstraint: "min_tabletL",
        filterValues: [
          {
            id: "true",
            text: "Yes",
          },
          {
            id: "false",
            text: "No",
          },
        ],
      },
    ];

    if (this.canManageUpgrades) {
      this.fColumns.push({
        name: "actions",
        header: "Action",
      });
    }

    this.fOptions = {
      orderBy: { column: "title", ascending: true },
      filterByColumn: true,
      debounce: 1000,
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
    };
  }

  public isRemoving(upgradeId: string): boolean {
    return !!this.fRemovingUpgrade[upgradeId];
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    if (!this.canManageUpgrades || data.row.isManageable) {
      return;
    }

    void this.$router.push(this.getItemUrl(data.row.id, "UpgradeEditPage"));
  }

  public async removeUpgrade(upgradeId: string): Promise<void> {
    if (this.isRemoving(upgradeId) || !this.canManageUpgrades) {
      return;
    }

    if (!confirm("Are you sure?")) {
      return;
    }

    Vue.set(this.fRemovingUpgrade, upgradeId, true);

    try {
      await this.fPlushieStore.removeUpgradeById(upgradeId);

      if (this.fTable) {
        this.fTable.refresh();
      }
    } finally {
      Vue.delete(this.fRemovingUpgrade, upgradeId);
    }
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    const upgradesCollection = await this.fUpgradeRepository.getList(
      page,
      limit,
      filter,
      order,
      false
    );
    const upgrades = upgradesCollection.getItems();

    const tableData: TableRow[] = [];

    for (const upgrade of upgrades) {
      tableData.push({
        id: upgrade.id,
        code: upgrade.code,
        title: upgrade.name,
        shortName: upgrade.shortName || "",
        displayToFactory: upgrade.displayToFactory,
        isManageable: upgrade.isManageable,
      });
    }

    return { data: tableData, count: upgradesCollection.totalItemsCount };
  }
}
