/* eslint-disable import/order */
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
import routes from "./router";

import store from "@/store";

if (!process.env || typeof process.env !== "object") {
  throw new Error("Environment is not available!");
}

const baseUrl = process.env.BASE_URL;
if (typeof baseUrl !== "string") {
  throw new Error("BASE_URL is not defined!");
}

const apiHost = process.env.VUE_APP_API_HOST;
if (typeof apiHost !== "string") {
  throw new Error("VUE_APP_API_HOST is not defined!");
}

const apiPath = process.env.VUE_APP_API_PATH;
if (typeof apiPath !== "string") {
  throw new Error("VUE_APP_API_PATH is not defined!");
}

const imageHandlerServiceUrl = process.env.VUE_APP_IMAGE_HANDLER_SERVICE_URL;
if (typeof imageHandlerServiceUrl !== "string") {
  throw new Error("VUE_APP_IMAGE_HANDLER_SERVICE_URL is not defined!");
}

const videoHandlerServiceUrl = process.env.VUE_APP_VIDEO_HANDLER_SERVICE_URL;
if (typeof videoHandlerServiceUrl !== "string") {
  throw new Error("VUE_APP_VIDEO_HANDLER_SERVICE_URL is not defined!");
}

const messageAttachmentHandlerServiceUrl =
  process.env.VUE_APP_MESSAGE_ATTACHMENT_HANDLER_SERVICE_URL;
if (typeof messageAttachmentHandlerServiceUrl !== "string") {
  throw new Error(
    "VUE_APP_MESSAGE_ATTACHMENT_HANDLER_SERVICE_URL is not defined!"
  );
}

const plushieAttachmentHandlerServiceUrl =
  process.env.VUE_APP_PLUSHIE_ATTACHMENT_HANDLER_SERVICE_URL;
if (typeof plushieAttachmentHandlerServiceUrl !== "string") {
  throw new Error(
    "VUE_APP_PLUSHIE_ATTACHMENT_HANDLER_SERVICE_URL is not defined!"
  );
}

const artworksHandlerServiceUrl =
  process.env.VUE_APP_ARTWORKS_HANDLER_SERVICE_URL;

if (typeof artworksHandlerServiceUrl !== "string") {
  throw new Error("VUE_APP_ARTWORKS_HANDLER_SERVICE_URL is not defined!");
}

const qaPhotosHandlerServiceUrl =
  process.env.VUE_APP_QA_PHOTOS_HANDLER_SERVICE_URL;
if (typeof qaPhotosHandlerServiceUrl !== "string") {
  throw new Error("VUE_APP_QA_PHOTOS_HANDLER_SERVICE_URL is not defined!");
}

const socketServerHost = process.env.VUE_APP_SOCKET_SERVER_HOST;

if (typeof socketServerHost !== "string") {
  throw new Error("VUE_APP_SOCKET_SERVER_HOST is not defined!");
}

const router = new Router({
  mode: "history",
  base: baseUrl,
  routes,
});

(Vue as any).router = router;

const eventBus = new Vue();

import IdGenerator from "@/lib/services/id-generator";
import ApiErrorParserService from "@/modules/api/api-error-parser.service";
import ApiResponseHandlerService from "@/modules/api/api-response-handler.service";
import ApiRequestParamsBuilderService from "@/modules/api/api-request-params-builder.service";
import ErrorConverterService from "@/modules/forms/error-converter.service.ts";

import ApiService from "@/modules/api/api.service";
import LoginRedirectResolverService from "@/modules/account/login-redirect-resolver.service";

import UserRepository from "@/modules/account/user.repository";
import UserInfoRepository from "@/modules/account/user-info.repository";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";

const apiErrorParser = new ApiErrorParserService();

const apiResponseHandler = new ApiResponseHandlerService(apiErrorParser);
const apiRequestParamsBuilder = new ApiRequestParamsBuilderService();

import axios, { AxiosInstance } from "axios";
import VueAxios from "vue-axios";

const api = new ApiService(
  axios,
  apiResponseHandler,
  apiRequestParamsBuilder,
  apiHost,
  apiPath
);

import VueAuth from "@websanova/vue-auth";

const apiServer: AxiosInstance = api.getServer();

Vue.use(VueAxios, apiServer);
import UserFactory from "@/modules/account/user.factory";

const userFactory = new UserFactory();

const storeWiper = new StoreWiperService(store);

Vue.use(VueAuth, {
  // auth:             require('@websanova/vue-auth/drivers/auth/bearer.js'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  auth: require("@/modules/account/bearer.driver.js"),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: require("@websanova/vue-auth/drivers/http/axios.1.x.js"),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  router: require("@websanova/vue-auth/drivers/router/vue-router.2.x.js"),
  rolesVar: "role",
  loginData: {
    url: apiPath + "authentication/login",
    method: "POST",
    redirect: false,
    fetchUser: true,
  },
  logoutData: {
    url: apiPath + "authentication/logout",
    method: "POST",
    redirect: "/account/login",
    makeRequest: false,
    success: () => {
      storeWiper.wipe();
    },
  },
  fetchData: {
    //url: process.env.VUE_APP_API_PATH! + "authentication/users/me",
    url: apiPath + "permissions",
    method: "GET",
    enabled: true,
  },
  refreshData: {
    url: apiPath + "authentication/refresh",
    method: "GET",
    enabled: false,
    interval: 30,
  },
  authRedirect: { path: "/account/login" },
  parseUserData: (data: any) => {
    const permissions: string[] = [];
    data.availableResources.forEach((item: any) => {
      permissions.push(item.name);
    });

    return new AuthenticatedUser(
      data.userId.toString(),
      data.metaRole.name,
      permissions
    );
  },
});

import AuthenticatedUserProvider from "./modules/account/authenticated-user-provider.service";
import AppAnalyticRequestProcessorService from "./modules/app-analytic/request-processor.service";

const authenticatedUserProvider = new AuthenticatedUserProvider(
  (Vue as any).auth
);

new AppAnalyticRequestProcessorService(apiServer, authenticatedUserProvider);

import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import IsUserUnderStrictModeResolver from "@/modules/strict-capacity-mode/is-user-under-strict-mode-resolver.service";
import IsPullModeEnabledResolver from "@/modules/strict-capacity-mode/is-pull-mode-enabled.resolver.service";

import BasicRepository from "@/modules/api/basic.repository";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import DirectoryValueFactory from "@/modules/api/directory-value.factory";

import AttachmentFactory from "./modules/messaging/attachment.factory";
import Attachment from "./modules/messaging/attachment.model";
import AttachmentRepository from "./modules/messaging/attachment.repository";
import CopyAttachmentToArtworkRequestRepository from "./modules/messaging/copy-attachment-to-artwork-request.repository";
import MetaRoleResourceFactory from "@/modules/account/meta-role-resource.factory";
import OrganizationFactory from "@/modules/account/organization.factory";
import OrganizationRepository from "@/modules/account/organization.repository";
import PermissionResourceFactory from "@/modules/account/permission-resource.factory";
import PermissionResourceRepository from "@/modules/account/permission-resource.repository";
import ProductionProcessRepository from "@/modules/production-process/production-process.repository";
import RoleFactory from "@/modules/account/role.factory";
import RoleRepository from "@/modules/account/role.repository";
import RoleResourceFactory from "@/modules/account/role-resource.factory";
import RoleResourceRepository from "@/modules/account/role-resource.repository";
import UserInfoFactory from "@/modules/account/user-info.factory";
import UserRoleFactory from "@/modules/account/user-role.factory";
import UserRoleRepository from "@/modules/account/user-role.repository";

import MetaRoleResource from "@/modules/account/meta-role-resource.model";
import Organization from "@/modules/account/organization.model";
import PermissionResource from "@/modules/account/permission-resource.model";
import Role from "@/modules/account/role.model";
import RoleResource from "@/modules/account/role-resource.model";
import User from "@/modules/account/user.model";
import UserInfo from "@/modules/account/user-info.model";
import UserRole from "@/modules/account/user-role.model";
import Plushie from "@/modules/plushie/plushie.model";
import PlushieFactory from "@/modules/plushie/plushie.factory";
import PlushieRepository from "@/modules/plushie/plushie.repository";
import PlushieFactorySummary from "./modules/plushie/plushie-factory-summary.model";
import PlushieFactorySummaryFactory from "./modules/plushie/plushie-factory-summary.factory";
import PlushieSummary from "@/modules/plushie/plushie-summary.model";
import PlushieSummaryFactory from "./modules/plushie/plushie-summary.factory";
import OrderInformationFactory from "@/modules/plushie/order-information.factory";
import OrderInformation from "@/modules/plushie/order-information.model";
import OrderInformationRepository from "@/modules/plushie/order-information.repository";
import FactoryFactory from "@/modules/factory/factory.factory";
import Factory from "@/modules/factory/factory.model";
import FactoryRepository from "@/modules/factory/factory.repository";
import FactoryPerformanceRepository from "@/modules/factory/factory-performance.repository";
import FileProcessingRepository from "@/modules/file-storage/file-processing.repository";
import FileStorageItemFactory from "@/modules/file-storage/item.factory";
import FileStorageItem from "@/modules/file-storage/item.model";
import PlushieStatus from "@/modules/plushie/plushie-status.model";
import PlushieStatusFactory from "./modules/plushie/plushie-status.factory";
import PlushieFactoryAssignmentFactory from "@/modules/factory/plushie-factory-assignment.factory";
import PlushieFactoryAssignmentRepository from "@/modules/factory/plushie-factory-assignment.repository";
import PlushieFactoryAssignment from "@/modules/factory/plushie-factory-asignment.model";
import Product from "@/modules/plushie/product.model";
import ProductFactory from "@/modules/plushie/product.factory";
import PlushieDesignInformationFactory from "@/modules/plushie/plushie-design-information.factory";
import PlushieDesignInformation from "@/modules/plushie/plushie-design-information.model";
import PlushieDesignInformationRepository from "@/modules/plushie/plushie-design-information.repository";
import PlushieImageFactory from "@/modules/plushie/plushie-image.factory";
import PlushieImage from "@/modules/plushie/plushie-image.model";
import PlushieStorageItemNameResolverService from "@/modules/plushie/plushie-storage-item-name-resolver.service";
import PlushieImageRepository from "@/modules/plushie/plushie-image.repository";
import PlushiePriority from "@/modules/plushie-priority/plushie-priority.model";
import PlushiePriorityFactory from "@/modules/plushie-priority/plushie-priority.factory";
import PlushieTagRelation from "@/modules/tag/plushie-tag-relation.model";
import PlushieTagRelationFactory from "@/modules/tag/plushie-tag-relation.factory";
import PlushieTagRelationRepository from "@/modules/tag/plushie-tag-relation.repository";
import PlushieUpgradeRelation from "@/modules/plushie/plushie-upgrade-relation.model";
import PlushieUpgradeRelationFactory from "@/modules/plushie/plushie-upgrade-relation.factory";
import PlushieUpgradeRelationRepository from "@/modules/plushie/plushie-upgrade-relation.repository";
import ProductionSchedule from "@/modules/due-dates-management/production-schedule.model";
import ProductionScheduleFactory from "@/modules/due-dates-management/production-schedule.factory";
import QaAssetFactory from "./modules/quality-inspection/qa-asset.factory";
import QaAsset from "./modules/quality-inspection/qa-asset.model";
import QaAssetRepository from "./modules/quality-inspection/qa-asset.repository";
import QaRejectionDetails from "./modules/quality-inspection/qa-rejection-details.model";
import QaRejectionDetailsRepository from "./modules/quality-inspection/qa-rejection-details.repository";
import QaRejectionDetailsFactory from "./modules/quality-inspection/qa-rejection-details.factory";
import QuestionTemplateFactory from "./modules/messaging/question-template.factory";
import QuestionTemplate from "./modules/messaging/question-template.model";
import QuestionTemplateRepository from "./modules/messaging/question-template.repository";
import QuestionTemplateProductRelation from "./modules/messaging/question-template-product-relation.model";
import QuestionTemplateProductRelationFactory from "./modules/messaging/question-template-product-relation.factory";
import QuestionTemplateProductRelationRepository from "./modules/messaging/question-template-product-relation.repository";
import QuestionTemplateStatusRelationFactory from "./modules/messaging/question-template-status-relation.factory";
import QuestionTemplateStatusRelation from "./modules/messaging/question-template-status-relation.model";
import QuestionTemplateStatusRelationRepository from "./modules/messaging/question-template-status-relation.repository";
import RejectionReasonFactory from "./modules/quality-inspection/rejection-reason.factory";
import RejectionReason from "./modules/quality-inspection/rejection-reason.model";
import RejectionReasonRepository from "./modules/quality-inspection/rejection-reason.repository";
import RejectionReasonProductRelationRepository from "./modules/quality-inspection/rejection-reason-product-relation.repository";
import RejectionReasonProductRelationFactory from "./modules/quality-inspection/rejection-reason-product-relation.factory";
import RejectionReasonProductRelation from "./modules/quality-inspection/rejection-reason-product-relation.model";
import TagRepository from "./modules/tag/tag.repository";
import Upgrade from "./modules/plushie/upgrade.model";
import UpgradeFactory from "@/modules/plushie/upgrade.factory";
import UpgradeRepository from "@/modules/plushie/upgrade.repository";
import ActionLogRecordFactory from "./modules/plushie/action-log-record.factory";
import ActionLogRecord from "./modules/plushie/action-log-record.model";
import ActionLogRecordRepository from "./modules/plushie/action-log-record.repository";
import BarcodePrintJobRepository from "./modules/barcode/barcode-print-job.repository";
import PlushieExportRepository from "./modules/document-export/plushie-export.repository";
import PlushieValueRelationRepository from "./modules/body-part/plushie-value-relation.repository";
import PlushieValueRelationFactory from "./modules/body-part/plushie-value-relation.factory";
import PlushieValueRelation from "./modules/body-part/plushie-value-relation.model";
import ValueFactory from "./modules/body-part/value.factory";
import Value from "./modules/body-part/value.model";
import BodyPartFactory from "./modules/body-part/body-part.factory";
import BodyPart from "./modules/body-part/body-part.model";
import BodyPartRepository from "./modules/body-part/body-part.repository";
import ValueRepository from "./modules/body-part/value.repository";
import QaDecisionRepository from "./modules/quality-inspection/qa-decision.repository";
import MessageFactory from "./modules/messaging/message.factory";
import Message from "./modules/messaging/message.model";
import MessageRepository from "./modules/messaging/message.repository";
import UserFactoryRelationFactory from "./modules/factory/user-factory-relation.factory";
import UserFactoryRelation from "./modules/factory/user-factory-relation.model";
import UserFactoryRelationRepository from "./modules/factory/user-factory-relation.repository";
import QuestionTemplateShortContentResolverService from "./modules/messaging/question-template-short-content-resolver.service";
import ProductionTimeNormFactory from "./modules/factory/production-time-norm.factory";
import ProductionTimeNorm from "./modules/factory/production-time-norm.model";
import ProductionTimeNormRepository from "./modules/factory/production-time-norm.repository";
import BulkShipmentFactory from "./modules/bulk-shipment/bulk-shipment.factory";
import BulkShipment from "./modules/bulk-shipment/bulk-shipment.model";
import BulkShipmentRepository from "./modules/bulk-shipment/bulk-shipment.repository";
import BulkShipmentItemFactory from "./modules/bulk-shipment/bulk-shipment-item.factory";
import BulkShipmentItem from "./modules/bulk-shipment/bulk-shipment-item.model";
import BulkShipmentItemRepository from "./modules/bulk-shipment/bulk-shipment-item.repository";
import CreateBulkShipmentService from "./modules/bulk-shipment/create-bulk-shipment.service";
import FactoryInvoice from "./modules/factory-invoice/factory-invoice.model";
import FactoryInvoiceRepository from "./modules/factory-invoice/factory-invoice.repository";
import FactoryInvoiceFactory from "./modules/factory-invoice/factory-invoice.factory";
import RemotePrintingConfigFactory from "./modules/barcode/remote-printing-config.factory";
import RemotePrintingConfig from "./modules/barcode/remote-printing-config.model";
import RemotePrintingConfigRepository from "./modules/barcode/remote-printing-config.repository";
import RemotePrintingCredentialsFactory from "./modules/barcode/remote-printing-credentials.factory";
import RemotePrintingCredentials from "./modules/barcode/remote-printing-credentials.model";
import RemotePrintingCredentialsRepository from "./modules/barcode/remote-printing-credentials.repository";
import RemotePrinterRepository from "./modules/barcode/remote-printer.repository";
import ProductAllocationFactory from "./modules/factory/product-allocation.factory";
import ProductAllocation from "./modules/factory/product-allocation.model";
import ProductAllocationRepository from "./modules/factory/product-allocation.repository";
import ProductionTimeNormHistoryItemFactory from "./modules/factory/production-time-norm-history-item.factory";
import ProductionTimeNormHistoryItem from "./modules/factory/production-time-norm-history-item.model";
import ProductionTimeNormHistoryItemRepository from "./modules/factory/production-time-norm-history-item.repository";
import PlushieAlertFactory from "./modules/plushie-alerts/alert.factory";
import PlushieAlert from "./modules/plushie-alerts/alert.model";
import PlushieAlertRepository from "./modules/plushie-alerts/alert.repository";
import PlushieAlertAssignmentFactory from "./modules/plushie-alerts/alert-assignment.factory";
import PlushieAlertAssignment from "./modules/plushie-alerts/alert-assignment.model";
import PlushieAlertAssignmentRepository from "./modules/plushie-alerts/alert-assignment.repository";
import TagAlertAssignRuleFactory from "./modules/plushie-alerts/tag-alert-assign-rule.factory";
import TagAlertAssignRule from "./modules/plushie-alerts/tag-alert-assign-rule.model";
import TagAlertAssignRuleRepository from "./modules/plushie-alerts/tag-alert-assign-rule.repository";
import UpgradeAllocation from "./modules/factory/upgrade-allocation.model";
import UpgradeAllocationFactory from "./modules/factory/upgrade-allocation.factory";
import UpgradeAllocationRepository from "./modules/factory/upgrade-allocation.repository";
import SettingFactory from "./modules/settings/setting.factory";
import Setting from "./modules/settings/setting.model";
import SettingRepository from "./modules/settings/setting.repository";
import RestrictedModeExcludedProductFactory from "./modules/factory-restricted-mode/excluded-product.factory";
import RestrictedModeExcludedProduct from "./modules/factory-restricted-mode/excluded-product.model";
import RestrictedModeExcludedProductRepository from "./modules/factory-restricted-mode/excluded-product.repository";
import RestrictedModeConfigFactory from "./modules/factory-restricted-mode/config.factory";
import RestrictedModeConfig from "./modules/factory-restricted-mode/config.model";
import RestrictedModeConfigRepository from "./modules/factory-restricted-mode/config.repository";
import RestrictedModeAlertFactory from "./modules/factory-restricted-mode/alert.factory";
import RestrictedModeAlertRepository from "./modules/factory-restricted-mode/alert.repository";
import RestrictedModeStatusFactory from "./modules/factory-restricted-mode/restricted-mode-status.factory";
import RestrictedModeStatusRepository from "./modules/factory-restricted-mode/restricted-mode-status.repository";
import BlockingPlushieFactory from "./modules/factory-restricted-mode/blocking-plushie.factory";
import BlockingPlushieRepository from "./modules/factory-restricted-mode/blocking-plushie.repository";
import BulkMoveToDesignService from "./modules/production-process/bulk-move-to-design.service";
import BulkMoveToProductionService from "./modules/production-process/bulk-move-to-production.service";
import BulkPrepareToShipmentService from "./modules/production-process/bulk-prepare-to-shipment.service";
import BulkReassignToFactoryService from "./modules/production-process/bulk-reassign-to-factory.service";
import ElementPriceFactory from "./modules/factory-invoice/element-price.factory";
import ElementPrice from "./modules/factory-invoice/element-price.model";
import ElementPriceRepository from "./modules/factory-invoice/element-price.repository";
import ElementPriceHistoryRecord from "./modules/factory-invoice/element-price-history-record.model";
import ElementPriceHistoryRecordFactory from "./modules/factory-invoice/element-price-history-record.factory";
import ElementPriceHistoryRecordRepository from "./modules/factory-invoice/element-price-history-record.repository";
import ExtraPaymentFactory from "./modules/factory-invoice/extra-payment.factory";
import ExtraPayment from "./modules/factory-invoice/extra-payment.model";
import ExtraPaymentRepository from "./modules/factory-invoice/extra-payment.repository";
import PenaltyBypassFactory from "./modules/factory-invoice/penalty-bypass.factory";
import PenaltyBypass from "./modules/factory-invoice/penalty-bypass.model";
import PenaltyBypassRepository from "./modules/factory-invoice/penalty-bypass.repository";
import BillingInfoRecordFactory from "./modules/factory-invoice/billing-info-record.factory";
import BillingInfoRecord from "./modules/factory-invoice/billing-info-record.model";
import BillingInfoRecordRepository from "./modules/factory-invoice/billing-info-record.repository";
import InvoiceItemFactory from "./modules/factory-invoice/invoice-item.factory";
import InvoiceItem from "./modules/factory-invoice/invoice-item.model";
import InvoiceItemRepository from "./modules/factory-invoice/invoice-item.repository";
import InvoiceItemElementFactory from "./modules/factory-invoice/invoice-item-element.factory";
import InvoiceItemElement from "./modules/factory-invoice/invoice-item-element.model";
import InvoiceItemElementRepository from "./modules/factory-invoice/invoice-item-element.repository";

import PaymentFactory from "./modules/factory-invoice/payment.factory";
import Payment from "./modules/factory-invoice/payment.model";
import PaymentRepository from "./modules/factory-invoice/payment.repository";
import CreatePaymentsService from "./modules/factory-invoice/create-payments.service";

import PenaltyRuleFactory from "./modules/factory-invoice/penalty-rule.factory";
import PenaltyRule from "./modules/factory-invoice/penalty-rule.model";
import PenaltyRuleRepository from "./modules/factory-invoice/penalty-rule.repository";
import PenaltyRuleHistoryRecord from "./modules/factory-invoice/penalty-rule-history-record.model";
import PenaltyRuleHistoryRecordFactory from "./modules/factory-invoice/penalty-rule-history-record.factory";
import PenaltyRuleHistoryRecordRepository from "./modules/factory-invoice/penalty-rule-history-record.repository";
import FactoryStrictCapacityMode from "./modules/strict-capacity-mode/factory-strict-capacity-mode.model";
import FactoryStrictCapacityModeFactory from "./modules/strict-capacity-mode/factory-strict-capacity-mode.factory";
import FactoryPlushieStatusCapacityFactory from "./modules/strict-capacity-mode/factory-plushie-status-capacity.factory";
import FactoryPlushieStatusCapacity from "./modules/strict-capacity-mode/factory-plushie-status-capacity.model";
import FactoryPlushieStatusCapacityRepository from "./modules/strict-capacity-mode/factory-plushie-status-capacity.repository";
import PlushieQueueControlRepository from "./modules/strict-capacity-mode/plushie-queue-control.repository";
import StatusSummaryRepository from "./modules/production-process/status-summary.repository";
import StatusSummaryFactory from "./modules/production-process/status-summary.factory";
import PlushiePriorityRepository from "./modules/plushie-priority/plushie-priority.repository";
import PreviewCountFactory from "./modules/customer-preview/preview-count.factory";
import PreviewCount from "./modules/customer-preview/preview-count.model";
import PreviewCountRepository from "./modules/customer-preview/preview-count.repository";
import QaDecisionFactory from "./modules/quality-inspection/qa-decision.factory";
import QaDecision from "./modules/quality-inspection/qa-decision.model";

import TagSearchResultFactory from "./modules/tag/tag-search-result.factory";
import TagSearchResultRepository from "./modules/tag/tag-search-result.repository";
import MessagingUserGroupRelationRepository from "./modules/messaging/user-group-relation.repository";
import MessagingUserGroupRelationFactory from "./modules/messaging/user-group-relation.factory";
import MessagingUserGroupRelation from "./modules/messaging/user-group-relation.model";
import MessagingUserGroupResolverService from "./modules/messaging/user-group-resolver.service";

import CurrentUserStateReporterFactory from "./modules/user-activity-notifier/current-user-state-reporter.factory";

import OtherUsersDetectorFactory from "./modules/user-activity-notifier/other-users-detector.factory";
import ActiveUserFactory from "./modules/user-activity-notifier/active-user.factory";

import UserPresenceNotifierFactory from "./modules/user-activity-notifier/user-presence-notifier.factory";
import UserActiveStateProviderFactory from "./modules/user-activity-notifier/user-active-state-provider.factory";

import AvailableMassActionsProviderService from "./modules/plushie/available-mass-actions-provider.service";
import QaDecisionRejectionReasonFactory from "./modules/quality-inspection/qa-decision-rejection-reason.factory";
import QaDecisionRejectionReason from "./modules/quality-inspection/qa-decision-rejection-reason.model";
import QaDecisionRejectionReasonRepository from "./modules/quality-inspection/qa-decision-rejection-reason.repository";
import PlushieStatusPeriodFactory from "./modules/production-process/plushie-status-period.factory";
import PlushieStatusPeriod from "./modules/production-process/plushie-status-period.model";
import PlushieStatusPeriodRepository from "./modules/production-process/plushie-status-period.repository";

import PhotoMarkupFactory from "./modules/photo-markup/photo-markup.factory";
import PhotoMarkup from "./modules/photo-markup/photo-markup.model";
import PhotoMarkupRepository from "./modules/photo-markup/photo-markup.repository";
import StoreWiperService from "./modules/general/store-wiper.service";
import DeactivatedUserFactory from "./modules/account/deactivated-user.factory";
import DeactivatedUser from "./modules/account/deactivated-user.model";
import DeactivatedUserRepository from "./modules/account/deactivated-user.repository";
import PlushieExportJobRepository from "./modules/document-export/plushie-export-job.repository";
import PlushiesGridExportJobRepository from "./modules/document-export/plushies-grid-export-job.repository";
import PlushiesGridExportService from "./modules/document-export/plushies-grid-export.service";
import BulkExportAsExcelService from "./modules/document-export/bulk-export-as-excel.service";
import HolidayPeriod from "./modules/holiday-period/holiday-period.model";
import HolidayPeriodFactory from "./modules/holiday-period/holiday-period.factory";

import HolidayPeriodPlushieRelationFactory from "./modules/holiday-period/plushie-relation.factory";
import HolidayPeriodPlushieRelation from "./modules/holiday-period/plushie-relation.model";
import HolidayPeriodPlushieRelationRepository from "./modules/holiday-period/plushie-relation.repository";

import HolidayPeriodProductSettingRepository from "./modules/holiday-period/product-setting.repository";
import HolidayPeriodProductSetting from "./modules/holiday-period/product-setting.model";
import HolidayPeriodProductSettingFactory from "./modules/holiday-period/product-setting.factory";

import RegularOrdersAssignJobRepository from "./modules/holiday-period/regular-orders-assign-job.repository";
import CutoffReportDownloadJobRepository from "./modules/holiday-period/cutoff-report-download-job.repository";
import PlushieArbitraryAttributeFactory from "./modules/plushie/plushie-arbitrary-attribute.factory";
import PlushieArbitraryAttribute from "./modules/plushie/plushie-arbitrary-attribute.model";
import PlushieArbitraryAttributeRepository from "./modules/plushie/plushie-arbitrary-attribute.repository";
import FactoryPlushieStatusQtyFactory from "./modules/strict-capacity-mode/factory-plushie-status-qty.factory";
import FactoryPlushieStatusQty from "./modules/strict-capacity-mode/factory-plushie-status-qty.model";
import FactoryPlushieStatusQtyRepository from "./modules/strict-capacity-mode/factory-plushie-status-qty.repository";
import LabelHeaderFactory from "./modules/barcode/label-header.factory";
import LabelHeader from "./modules/barcode/label-header.model";
import LabelHeaderRepository from "./modules/barcode/label-header.repository";
import LabelHeaderProductRelationFactory from "./modules/barcode/label-header-product-relation.factory";
import LabelHeaderProductRelation from "./modules/barcode/label-header-product-relation.model";
import LabelHeaderProductRelationRepository from "./modules/barcode/label-header-product-relation.repository";
import FactoryInvoicesSettingFactory from "./modules/factory-invoice/factory-invoices-setting.factory";
import FactoryInvoicesSetting from "./modules/factory-invoice/factory-invoices-setting.model";
import FactoryInvoicesSettingRepository from "./modules/factory-invoice/factory-invoices-setting.repository";

import FutureTurnaroundFactory from "./modules/reports/future-turnaround.factory";
import FutureTurnaroundRepository from "./modules/reports/future-turnaround.repository";
import QualityInspectionReportFactory from "./modules/reports/quality-inspection-report.factory";
import QualityInspectionReportRepository from "./modules/reports/quality-inspection-report.repository";
import ReviewerReportFactory from "./modules/reports/reviewer-report.factory";
import ReviewerReportRepository from "./modules/reports/reviewer-report.repository";
import ThroughputReportFactory from "./modules/reports/throughput-report.factory";
import ThroughputReportRepository from "./modules/reports/throughput-report.repository";
import InReviewSnapshotFactory from "./modules/reports/in-review-snapshot.factory";
import InReviewSnapshotRepository from "./modules/reports/in-review-snapshot.repository";
import ProductionSnapshotFactory from "./modules/reports/production-snapshot.factory";
import ProductionSnapshotRepository from "./modules/reports/production-snapshot.repository";
import Projection from "./modules/reports/projection.model";
import ProjectionFactory from "./modules/reports/projection.factory";
import ProjectionRepository from "./modules/reports/projection.repository";
import ProjectionStatsFactory from "./modules/reports/projection-stats.factory";
import ProjectionStatsRepository from "./modules/reports/projection-stats.repository";
import ProductionEstimateRepository from "./modules/reports/production-estimate.repository";
import ProductionExtendedRepository from "./modules/reports/production-extended.repository";

import OperatorSession from "./modules/statistics/operator-session.model";
import OperatorSessionFactory from "./modules/statistics/operator-session.factory";
import OperatorSessionRepository from "./modules/statistics/operator-session.repository";
import FactoryInvoicingSettingsFactory from "./modules/factory/factory-invoicing-settings.factory";
import FactoryInvoicingSettings from "./modules/factory/factory-invoicing-settings.model";
import FactoryInvoicingSettingsRepository from "./modules/factory/factory-invoicing-settings.repository";

import ProductionTimeAlertFactory from "./modules/reports/production-time-alert.factory";
import ProductionTimeAlertRepository from "./modules/reports/production-time-alert.repository";
import PlushiesArtworksExportJobRepository from "./modules/plushie/plushies-artworks-export-job.repository";
import BulkPlushiesArtworksDownloadService from "./modules/plushie/bulk-plushies-artworks-download.service";
import AutoApprovementExclude from "./modules/customer-preview/auto-approvement-exclude.model";
import AutoApprovementExcludeFactory from "./modules/customer-preview/auto-approvement-exclude.factory";
import AutoApprovementExcludeRepository from "./modules/customer-preview/auto-approvement-exclude.repository";

import BatchCreationRequestRepository from "./modules/bulk-project/batch-creation-request.repository";
import BulkProject from "./modules/bulk-project/bulk-project.model";
import BulkProjectFactory from "./modules/bulk-project/bulk-project.factory";
import BulkProjectRepository from "./modules/bulk-project/bulk-project.repository";
import BulkProjectPlushieRelation from "./modules/bulk-project/bulk-project-plushie-relation.model";
import BulkProjectPlushieRelationFactory from "./modules/bulk-project/bulk-project-plushie-relation.factory";
import BulkProjectPlushieRelationRepository from "./modules/bulk-project/bulk-project-plushie-relation.repository";
import BulkProjectPlushieType from "./modules/bulk-project/bulk-project-plushie-type.model";
import BulkProjectPlushieTypeFactory from "./modules/bulk-project/bulk-project-plushie-type.factory";
import BulkProjectPlushieTypeRepository from "./modules/bulk-project/bulk-project-plushie-type.repository";
import BulkProjectBatchInformation from "./modules/bulk-project/bulk-project-batch-information.model";
import BulkProjectBatchInformationFactory from "./modules/bulk-project/bulk-project-batch-information.factory";
import BulkProjectBatchInformationRepository from "./modules/bulk-project/bulk-project-batch-information.repository";
import Country from "./modules/country/country.model";
import CountryFactory from "./modules/country/country.factory";
import CountryRegionFactory from "./modules/country/country-region.factory";
import CountryRegion from "./modules/country/country-region.model";
import ShippingInformation from "./modules/plushie/shipping-information.model";
import ShippingInformationFactory from "./modules/plushie/shipping-information.factory";
import ShippingInformationRepository from "./modules/plushie/shipping-information.repository";
import TrackingInformationFactory from "./modules/plushie/tracking-information.factory";
import TrackingInformation from "./modules/plushie/tracking-information.model";
import TrackingInformationRepository from "./modules/plushie/tracking-information.repository";
import ProductionTimeHistory from "./modules/factory-invoice/production-time-history.model";
import ProductionTimeHistoryFactory from "./modules/factory-invoice/production-time-history.factory";
import DirectShipmentIntention from "./modules/production-process/direct-shipment-intention.model";
import DirectShipmentIntentionRepository from "./modules/production-process/direct-shipment-intention.repository";
import DirectShipmentIntentionFactory from "./modules/production-process/direct-shipment-intention.factory";
import DirectShipmentIntentionRuleFactory from "./modules/production-process/direct-shipment-intention-rule.factory";
import DirectShipmentIntentionRule from "./modules/production-process/direct-shipment-intention-rule.model";
import DirectShipmentIntentionRuleRepository from "./modules/production-process/direct-shipment-intention-rule.repository";
import PlushieAttachmentFactory from "./modules/plushie/plushie-attachment.factory";
import PlushieAttachment from "./modules/plushie/plushie-attachment.model";
import PlushieAttachmentRepository from "./modules/plushie/plushie-attachment.repository";
import PlushieAttachmentTypeFactory from "./modules/plushie/plushie-attachment-type.factory";
import PlushieAttachmentType from "./modules/plushie/plushie-attachment-type.model";
import PlushieAttachmentTypeRepository from "./modules/plushie/plushie-attachment-type.repository";
import CarrierFactory from "./modules/plushie/carrier.factory";
import Carrier from "./modules/plushie/carrier.model";
import CarrierRepository from "./modules/plushie/carrier.repository";
import BucketFileHandlerService from "./modules/file-storage/bucket-file-handler.service";
import BucketFileDownloaderService from "./modules/file-storage/bucket-file-downloader.service";

import EmailLayoutRepository from "./modules/emails-management/email-layout.repository";
import EmailLayoutFactory from "./modules/emails-management/email-layout.factory";
import EmailLayout from "./modules/emails-management/email-layout.model";
import EmailTemplateFactory from "./modules/emails-management/email-template.factory";
import EmailTemplate from "./modules/emails-management/email-template.model";
import EmailTemplateRepository from "./modules/emails-management/email-template.repository";
import SenderFactory from "./modules/emails-management/sender.factory";
import Sender from "./modules/emails-management/sender.model";
import SenderRepository from "./modules/emails-management/sender.repository";
import QaPhotoRotationService from "./modules/quality-inspection/qa-photo-rotation.service";

const idGenerator = new IdGenerator();
const imageHandlerService = new ImageHandlerService(imageHandlerServiceUrl);
const videoHandlerService = new BucketFileHandlerService(
  videoHandlerServiceUrl
);
const messageAttachmentHandlerService = new BucketFileHandlerService(
  messageAttachmentHandlerServiceUrl
);
const plushieAttachmentHandlerService = new BucketFileHandlerService(
  plushieAttachmentHandlerServiceUrl
);
const artworksHandlerService = new BucketFileHandlerService(
  artworksHandlerServiceUrl
);
const qaPhotosHandlerService = new BucketFileHandlerService(
  qaPhotosHandlerServiceUrl
);
const isUserUnderStrictModeResolver = new IsUserUnderStrictModeResolver(
  authenticatedUserProvider
);
const isPullModeEnabledResolver = new IsPullModeEnabledResolver(
  authenticatedUserProvider
);

const availableMassActionsProviderService = new AvailableMassActionsProviderService(
  authenticatedUserProvider,
  isUserUnderStrictModeResolver,
  isPullModeEnabledResolver
);

const bulkMoveToDesignService = new BulkMoveToDesignService();
const bulkMoveToProductionService = new BulkMoveToProductionService();
const bulkPrepareToShipmentService = new BulkPrepareToShipmentService();
const bulkReassignToFactoryService = new BulkReassignToFactoryService();
const createBulkShipmentService = new CreateBulkShipmentService();

const videoDownloaderService = new BucketFileDownloaderService(
  videoHandlerService
);
const messageAttachmentDownloaderService = new BucketFileDownloaderService(
  messageAttachmentHandlerService
);
const plushieAttachmentDownloaderService = new BucketFileDownloaderService(
  plushieAttachmentHandlerService
);
const artworksDownloaderService = new BucketFileDownloaderService(
  artworksHandlerService
);
const qaPhotosDownloaderService = new BucketFileDownloaderService(
  qaPhotosHandlerService
);

const questionTemplateShortContentResolverService = new QuestionTemplateShortContentResolverService();

const directoryValueFactory = new DirectoryValueFactory();
const directoryValueResponseParser = new ApiCollectionResponseParserService<DirectoryValue>();

const plushieAlertFactory = new PlushieAlertFactory();
const plushieAlertResponseParser = new ApiCollectionResponseParserService<PlushieAlert>();

const plushieAlertRepository = new PlushieAlertRepository(
  api,
  plushieAlertFactory,
  plushieAlertResponseParser
);

const plushieAlertAssignmentFactory = new PlushieAlertAssignmentFactory();
const plushieAlertAssignmentResponseParser = new ApiCollectionResponseParserService<PlushieAlertAssignment>();

const plushieAlertAssignmentRepository = new PlushieAlertAssignmentRepository(
  api,
  plushieAlertAssignmentFactory,
  plushieAlertAssignmentResponseParser
);

const plushieAttachmentFactory = new PlushieAttachmentFactory();
const plushieAttachmentResponseParser = new ApiCollectionResponseParserService<PlushieAttachment>();

const plushieAttachmentRepository = new PlushieAttachmentRepository(
  api,
  plushieAttachmentFactory,
  plushieAttachmentResponseParser
);

const plushieAttachmentTypeFactory = new PlushieAttachmentTypeFactory();
const plushieAttachmentTypeResponseParser = new ApiCollectionResponseParserService<PlushieAttachmentType>();

const plushieAttachmentTypeRepository = new PlushieAttachmentTypeRepository(
  api,
  plushieAttachmentTypeFactory,
  plushieAttachmentTypeResponseParser
);

const carrierFactory = new CarrierFactory();
const carrierResponseParser = new ApiCollectionResponseParserService<Carrier>();
const carrierRepository = new CarrierRepository(
  api,
  carrierFactory,
  carrierResponseParser
);

const attachmentFactory = new AttachmentFactory();
const attachmentResponseParser = new ApiCollectionResponseParserService<Attachment>();

const attachmentRepository = new AttachmentRepository(
  api,
  attachmentFactory,
  attachmentResponseParser
);

const copyAttachmentToArtworkRequestRepository = new CopyAttachmentToArtworkRequestRepository(
  api
);

const countryFactory = new CountryFactory();
const countryResponseParser = new ApiCollectionResponseParserService<Country>();
const countryRepository = new BasicRepository<Country>(
  "countries",
  api,
  countryFactory,
  countryResponseParser
);

const countryRegionFactory = new CountryRegionFactory();
const countryRegionResponseParser = new ApiCollectionResponseParserService<CountryRegion>();
const countryRegionRepository = new BasicRepository<CountryRegion>(
  "country_regions",
  api,
  countryRegionFactory,
  countryRegionResponseParser
);

const actionLogRecordFactory = new ActionLogRecordFactory();
const actionLogRecordResponseParser = new ApiCollectionResponseParserService<ActionLogRecord>();

const actionLogRecordRepository = new ActionLogRecordRepository(
  api,
  actionLogRecordFactory,
  actionLogRecordResponseParser
);

const barcodePrintJobRepository = new BarcodePrintJobRepository(api);

const billingInfoRecordFactory = new BillingInfoRecordFactory();
const billingInfoRecordResponseParser = new ApiCollectionResponseParserService<BillingInfoRecord>();

const billingInfoRecordRepository = new BillingInfoRecordRepository(
  api,
  billingInfoRecordFactory,
  billingInfoRecordResponseParser
);

const blockingPlushieFactory = new BlockingPlushieFactory();

const blockingPlushieRepository = new BlockingPlushieRepository(
  api,
  blockingPlushieFactory
);

const bodyPartFactory = new BodyPartFactory();
const bodyPartResponseParser = new ApiCollectionResponseParserService<BodyPart>();

const bodyPartRepository = new BodyPartRepository(
  api,
  bodyPartFactory,
  bodyPartResponseParser
);

const bodyPartValueFactory = new ValueFactory();
const bodyPartValueResponseParser = new ApiCollectionResponseParserService<Value>();

const bodyPartValueRepository = new ValueRepository(
  api,
  bodyPartValueFactory,
  bodyPartValueResponseParser
);

const bodyPartValuePlushieRelationFactory = new PlushieValueRelationFactory();
const bodyPartValuePlushieRelationResponseParser = new ApiCollectionResponseParserService<PlushieValueRelation>();

const bodyPartValuePlushieRelationRepository = new PlushieValueRelationRepository(
  api,
  bodyPartValuePlushieRelationFactory,
  bodyPartValuePlushieRelationResponseParser
);

const bulkShipmentFactory = new BulkShipmentFactory();
const bulkShipmentResponseParser = new ApiCollectionResponseParserService<BulkShipment>();

const bulkShipmentRepository = new BulkShipmentRepository(
  api,
  bulkShipmentFactory,
  bulkShipmentResponseParser
);

const bulkShipmentStatusRepository = new BasicRepository<DirectoryValue>(
  "bulk_shipment_statuses",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const bulkShipmentItemFactory = new BulkShipmentItemFactory();
const bulkShipmentItemResponseParser = new ApiCollectionResponseParserService<BulkShipmentItem>();

const bulkShipmentItemRepository = new BulkShipmentItemRepository(
  api,
  bulkShipmentItemFactory,
  bulkShipmentItemResponseParser
);

const deactivatedUserFactory = new DeactivatedUserFactory();
const deactivatedUserResponseParser = new ApiCollectionResponseParserService<DeactivatedUser>();

const deactivatedUserRepository = new DeactivatedUserRepository(
  api,
  deactivatedUserFactory,
  deactivatedUserResponseParser
);

const excludedProductFactory = new RestrictedModeExcludedProductFactory();
const excludedProductResponseParser = new ApiCollectionResponseParserService<RestrictedModeExcludedProduct>();

const excludedProductRepository = new RestrictedModeExcludedProductRepository(
  api,
  excludedProductFactory,
  excludedProductResponseParser
);

const extraPaymentAuthorRepository = new BasicRepository<DirectoryValue>(
  "extra_payment_authors",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const factoryInvoiceHistoryActionRepository = new BasicRepository<DirectoryValue>(
  "history_actions",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const factoryInvoiceElementTypeRepository = new BasicRepository<DirectoryValue>(
  "element_types",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const elementPriceFactory = new ElementPriceFactory();
const elementPriceResponseParser = new ApiCollectionResponseParserService<ElementPrice>();

const elementPriceRepository = new ElementPriceRepository(
  api,
  elementPriceFactory,
  elementPriceResponseParser
);

const elementPriceHistoryRecordFactory = new ElementPriceHistoryRecordFactory();
const elementPriceHistoryRecordResponseParser = new ApiCollectionResponseParserService<ElementPriceHistoryRecord>();

const elementPriceHistoryRecordRepository = new ElementPriceHistoryRecordRepository(
  api,
  elementPriceHistoryRecordFactory,
  elementPriceHistoryRecordResponseParser
);

const extraPaymentFaultRepository = new BasicRepository<DirectoryValue>(
  "extra_payment_faults",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const extraPaymentFactory = new ExtraPaymentFactory();
const extraPaymentResponseParser = new ApiCollectionResponseParserService<ExtraPayment>();

const extraPaymentRepository = new ExtraPaymentRepository(
  api,
  extraPaymentFactory,
  extraPaymentResponseParser
);

const paymentFactory = new PaymentFactory();
const paymentResponseParser = new ApiCollectionResponseParserService<Payment>();

const paymentRepository = new PaymentRepository(
  api,
  paymentFactory,
  paymentResponseParser
);

const createPaymentsService = new CreatePaymentsService();

const factoryInvoiceFactory = new FactoryInvoiceFactory();
const factoryInvoiceResponseParser = new ApiCollectionResponseParserService<FactoryInvoice>();

const factoryInvoiceStatusRepository = new BasicRepository<DirectoryValue>(
  "invoice_statuses",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const factoryInvoiceRepository = new FactoryInvoiceRepository(
  api,
  factoryInvoiceFactory,
  factoryInvoiceResponseParser
);

const factoryInvoicesSettingFactory = new FactoryInvoicesSettingFactory();
const factoryInvoicesSettingResponseParser = new ApiCollectionResponseParserService<FactoryInvoicesSetting>();

const factoryInvoicesSettingRepository = new FactoryInvoicesSettingRepository(
  api,
  factoryInvoicesSettingFactory,
  factoryInvoicesSettingResponseParser
);

const factoryInvoicingSettingsFactory = new FactoryInvoicingSettingsFactory();
const factoryInvoicingSettingsResponseParser = new ApiCollectionResponseParserService<FactoryInvoicingSettings>();

const factoryInvoicingSettingsRepository = new FactoryInvoicingSettingsRepository(
  api,
  factoryInvoicingSettingsFactory,
  factoryInvoicingSettingsResponseParser
);

const factoryRestrictedModeAlertFactory = new RestrictedModeAlertFactory();

const factoryRestrictedModeAlertRepository = new RestrictedModeAlertRepository(
  "factory_restricted_mode/alerts",
  api,
  factoryRestrictedModeAlertFactory
);

const factoryRestrictedModeConfigFactory = new RestrictedModeConfigFactory();
const factoryRestrictedModeConfigResponseParser = new ApiCollectionResponseParserService<RestrictedModeConfig>();

const factoryRestrictedModeConfigRepository = new RestrictedModeConfigRepository(
  api,
  factoryRestrictedModeConfigFactory,
  factoryRestrictedModeConfigResponseParser
);

const factoryRestrictedModeStatusFactory = new RestrictedModeStatusFactory();

const factoryRestrictedModeStatusRepository = new RestrictedModeStatusRepository(
  "factory_restricted_mode/statuses",
  api,
  factoryRestrictedModeStatusFactory
);

const factoryStrictCapacityModeFactory = new FactoryStrictCapacityModeFactory();
const factoryStrictCapacityModeResponseParser = new ApiCollectionResponseParserService<FactoryStrictCapacityMode>();

const factoryStrictCapacityModeRepository = new BasicRepository<FactoryStrictCapacityMode>(
  "factory_strict_capacity_modes",
  api,
  factoryStrictCapacityModeFactory,
  factoryStrictCapacityModeResponseParser
);

const factoryPlushieStatusCapacityFactory = new FactoryPlushieStatusCapacityFactory();
const factoryPlushieStatusCapacityResponseParser = new ApiCollectionResponseParserService<FactoryPlushieStatusCapacity>();

const factoryPlushieStatusCapacityRepository = new FactoryPlushieStatusCapacityRepository(
  api,
  factoryPlushieStatusCapacityFactory,
  factoryPlushieStatusCapacityResponseParser
);

const factoryPlushieStatusQtyFactory = new FactoryPlushieStatusQtyFactory();
const factoryPlushieStatusQtyResponseParser = new ApiCollectionResponseParserService<FactoryPlushieStatusQty>();

const factoryPlushieStatusQtyRepository = new FactoryPlushieStatusQtyRepository(
  api,
  factoryPlushieStatusQtyFactory,
  factoryPlushieStatusQtyResponseParser
);

const userInfoFactory = new UserInfoFactory();

const userResponseParser = new ApiCollectionResponseParserService<User>();

const userRepository = new UserRepository(
  api,
  userFactory,
  userResponseParser,
  apiErrorParser,
  (Vue as any).auth,
  eventBus
);

const userInfoResponseParser = new ApiCollectionResponseParserService<UserInfo>();

const userInfoRepository = new UserInfoRepository(
  api,
  userInfoFactory,
  userInfoResponseParser
);

const metaRoleRepository = new BasicRepository<DirectoryValue>(
  "meta_roles",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const metaRoleResourceFactory = new MetaRoleResourceFactory();
const metaRoleResourceResponseParser = new ApiCollectionResponseParserService<MetaRoleResource>();

const metaRoleResourceRepository = new BasicRepository<MetaRoleResource>(
  "meta_role_available_resources",
  api,
  metaRoleResourceFactory,
  metaRoleResourceResponseParser
);

const permissionResourceFactory = new PermissionResourceFactory();
const permissionResourceResponseParser = new ApiCollectionResponseParserService<PermissionResource>();

const permissionResourceRepository = new PermissionResourceRepository(
  api,
  permissionResourceFactory,
  permissionResourceResponseParser
);

const roleFactory = new RoleFactory();
const roleResponseParser = new ApiCollectionResponseParserService<Role>();

const roleRepository = new RoleRepository(api, roleFactory, roleResponseParser);

const roleResourceFactory = new RoleResourceFactory();
const roleResourceResponseParser = new ApiCollectionResponseParserService<RoleResource>();

const roleResourceRepository = new RoleResourceRepository(
  api,
  roleResourceFactory,
  roleResourceResponseParser
);

const serviceRepository = new BasicRepository<DirectoryValue>(
  "services",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const userRoleFactory = new UserRoleFactory();
const userRoleResponseParser = new ApiCollectionResponseParserService<UserRole>();

const userRoleRepository = new UserRoleRepository(
  api,
  userRoleFactory,
  userRoleResponseParser
);

const factoryFactory = new FactoryFactory();
const factoryResponseParser = new ApiCollectionResponseParserService<Factory>();

const factoryRepository = new FactoryRepository(
  api,
  factoryFactory,
  factoryResponseParser
);

const factoryPerformanceRepository = new FactoryPerformanceRepository(api);

const fileStorageItemFactory = new FileStorageItemFactory();
const fileStorageItemResponseParser = new ApiCollectionResponseParserService<FileStorageItem>();

const fileStorageItemRepository = new BasicRepository<FileStorageItem>(
  "file-storage/items",
  api,
  fileStorageItemFactory,
  fileStorageItemResponseParser
);

const fileProcessingRepository = new FileProcessingRepository(
  api,
  fileStorageItemFactory
);

const historyTypeRepository = new BasicRepository<DirectoryValue>(
  "history_types",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const invoiceItemFactory = new InvoiceItemFactory();
const invoiceItemResponseParser = new ApiCollectionResponseParserService<InvoiceItem>();

const invoiceItemRepository = new InvoiceItemRepository(
  api,
  invoiceItemFactory,
  invoiceItemResponseParser
);

const invoiceItemElementFactory = new InvoiceItemElementFactory();
const invoiceItemElementResponseParser = new ApiCollectionResponseParserService<InvoiceItemElement>();

const invoiceItemElementRepository = new InvoiceItemElementRepository(
  api,
  invoiceItemElementFactory,
  invoiceItemElementResponseParser
);

const labelHeaderFactory = new LabelHeaderFactory();
const labelHeaderResponseParser = new ApiCollectionResponseParserService<LabelHeader>();

const labelHeaderRepository = new LabelHeaderRepository(
  api,
  labelHeaderFactory,
  labelHeaderResponseParser
);

const labelHeaderProductRelationFactory = new LabelHeaderProductRelationFactory();
const labelHeaderProductRelationResponseParser = new ApiCollectionResponseParserService<LabelHeaderProductRelation>();

const labelHeaderProductRelationRepository = new LabelHeaderProductRelationRepository(
  api,
  labelHeaderProductRelationFactory,
  labelHeaderProductRelationResponseParser
);

const languageRepository = new BasicRepository<DirectoryValue>(
  "languages",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const messagingUserGroupResolverService = new MessagingUserGroupResolverService();

const messageFactory = new MessageFactory();
const messageResponseParser = new ApiCollectionResponseParserService<Message>();

const messageRepository = new MessageRepository(
  api,
  messageFactory,
  messageResponseParser
);

const messagingUserGroupRelationFactory = new MessagingUserGroupRelationFactory();
const messagingUserGroupRelationResponseParser = new ApiCollectionResponseParserService<MessagingUserGroupRelation>();

const messagingUserGroupRelationRepository = new MessagingUserGroupRelationRepository(
  api,
  messagingUserGroupRelationFactory,
  messagingUserGroupRelationResponseParser
);

const orderInformationFactory = new OrderInformationFactory();
const orderInformationResponseParser = new ApiCollectionResponseParserService<OrderInformation>();

const orderInformationRepository = new OrderInformationRepository(
  api,
  orderInformationFactory,
  orderInformationResponseParser
);

const organizationFactory = new OrganizationFactory();
const organizationResponseParser = new ApiCollectionResponseParserService<Organization>();

const organizationRepository = new OrganizationRepository(
  api,
  organizationFactory,
  organizationResponseParser
);

const organizationTypeRepository = new BasicRepository<DirectoryValue>(
  "organization_types",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const penaltyBypassFactory = new PenaltyBypassFactory();
const penaltyBypassResponseParser = new ApiCollectionResponseParserService<PenaltyBypass>();

const penaltyBypassRepository = new PenaltyBypassRepository(
  api,
  penaltyBypassFactory,
  penaltyBypassResponseParser
);

const penaltyBypassTypeRepository = new BasicRepository<DirectoryValue>(
  "penalty_bypass_types",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const photoMarkupFactory = new PhotoMarkupFactory();
const photoMarkupResponseParser = new ApiCollectionResponseParserService<PhotoMarkup>();

const photoMarkupRepository = new PhotoMarkupRepository(
  api,
  photoMarkupFactory,
  photoMarkupResponseParser
);

const penaltyRuleFactory = new PenaltyRuleFactory();
const penaltyRuleResponseParser = new ApiCollectionResponseParserService<PenaltyRule>();

const penaltyRuleRepository = new PenaltyRuleRepository(
  api,
  penaltyRuleFactory,
  penaltyRuleResponseParser
);

const penaltyRuleHistoryRecordFactory = new PenaltyRuleHistoryRecordFactory();
const penaltyRuleHistoryRecordResponseParser = new ApiCollectionResponseParserService<PenaltyRuleHistoryRecord>();

const penaltyRuleHistoryRecordRepository = new PenaltyRuleHistoryRecordRepository(
  api,
  penaltyRuleHistoryRecordFactory,
  penaltyRuleHistoryRecordResponseParser
);

const plushieFactory = new PlushieFactory();
const plushieResponseParser = new ApiCollectionResponseParserService<Plushie>();

const plushieQueueControlRepository = new PlushieQueueControlRepository(
  api,
  plushieFactory,
  plushieResponseParser
);

const plushieRepository = new PlushieRepository(
  api,
  plushieFactory,
  plushieResponseParser
);

const plushieExportJobRepository = new PlushieExportJobRepository(
  "plushie_export_jobs",
  api
);

const plushiesGridExportJobRepository = new PlushiesGridExportJobRepository(
  api
);

const plushiesGridExportService = new PlushiesGridExportService(
  plushiesGridExportJobRepository,
  window
);

const plushieExportRepository = new PlushieExportRepository(
  "plushie_exports",
  api
);

const plushiesArtworksExportJobRepository = new PlushiesArtworksExportJobRepository(
  "plushies_artworks_export_jobs",
  api
);

const bulkPlushiesArtworksDownloadService = new BulkPlushiesArtworksDownloadService(
  plushiesArtworksExportJobRepository,
  window
);

const plushieFactorySummaryFactory = new PlushieFactorySummaryFactory();
const plushieFactorySummaryResponseParser = new ApiCollectionResponseParserService<PlushieFactorySummary>();

const plushieFactorySummaryRepository = new BasicRepository<PlushieFactorySummary>(
  "plushie_factory_summaries",
  api,
  plushieFactorySummaryFactory,
  plushieFactorySummaryResponseParser
);

const plushieDesignInformationFactory = new PlushieDesignInformationFactory();
const plushieDesignInformationResponseParser = new ApiCollectionResponseParserService<PlushieDesignInformation>();

const plushieDesignInformationRepository = new PlushieDesignInformationRepository(
  api,
  plushieDesignInformationFactory,
  plushieDesignInformationResponseParser
);

const plushieFactoryAssignmentFactory = new PlushieFactoryAssignmentFactory();
const plushieFactoryAssignmentResponseParser = new ApiCollectionResponseParserService<PlushieFactoryAssignment>();

const plushieFactoryAssignmentRepository = new PlushieFactoryAssignmentRepository(
  api,
  plushieFactoryAssignmentFactory,
  plushieFactoryAssignmentResponseParser
);

const plushieImageFactory = new PlushieImageFactory();
const plushieImageResponseParser = new ApiCollectionResponseParserService<PlushieImage>();

const plushieStorageItemNameResolverService = new PlushieStorageItemNameResolverService();

const plushieImageRepository = new PlushieImageRepository(
  api,
  plushieImageFactory,
  plushieImageResponseParser
);

const plushieArbitraryAttributeFactory = new PlushieArbitraryAttributeFactory();
const plushieArbitraryAttributeResponseParser = new ApiCollectionResponseParserService<PlushieArbitraryAttribute>();

const plushieArbitraryAttributeRepository = new PlushieArbitraryAttributeRepository(
  api,
  plushieArbitraryAttributeFactory,
  plushieArbitraryAttributeResponseParser
);

const plushieStatusFactory = new PlushieStatusFactory();
const plushieStatusResponseParser = new ApiCollectionResponseParserService<PlushieStatus>();

const plushieStatusRepository = new BasicRepository<PlushieStatus>(
  "plushie_statuses",
  api,
  plushieStatusFactory,
  plushieStatusResponseParser
);

const plushieSummaryFactory = new PlushieSummaryFactory();
const plushieSummaryResponseParser = new ApiCollectionResponseParserService<PlushieSummary>();

const plushieSummaryRepository = new BasicRepository<PlushieSummary>(
  "plushie_summaries",
  api,
  plushieSummaryFactory,
  plushieSummaryResponseParser
);

const plushiePriorityFactory = new PlushiePriorityFactory();
const plushiePriorityResponseParser = new ApiCollectionResponseParserService<PlushiePriority>();

const plushiePriorityRepository = new PlushiePriorityRepository(
  api,
  plushiePriorityFactory,
  plushiePriorityResponseParser
);

const plushieTagRelationFactory = new PlushieTagRelationFactory();
const plushieTagRelationResponseParser = new ApiCollectionResponseParserService<PlushieTagRelation>();

const plushieTagRelationRepository = new PlushieTagRelationRepository(
  api,
  plushieTagRelationFactory,
  plushieTagRelationResponseParser
);

const plushieUpgradeRelationFactory = new PlushieUpgradeRelationFactory();
const plushieUpgradeRelationResponseParser = new ApiCollectionResponseParserService<PlushieUpgradeRelation>();

const plushieUpgradeRelationRepository = new PlushieUpgradeRelationRepository(
  api,
  plushieUpgradeRelationFactory,
  plushieUpgradeRelationResponseParser
);

const productionProcessRepository = new ProductionProcessRepository(
  api,
  plushieFactory,
  plushieResponseParser
);

const statusSummaryFactory = new StatusSummaryFactory();

const statusSummaryRepository = new StatusSummaryRepository(
  api,
  statusSummaryFactory
);

const plushieStatusPeriodFactory = new PlushieStatusPeriodFactory();
const plushieStatusPeriodResponseParser = new ApiCollectionResponseParserService<PlushieStatusPeriod>();

const plushieStatusPeriodRepository = new PlushieStatusPeriodRepository(
  api,
  plushieStatusPeriodFactory,
  plushieStatusPeriodResponseParser
);

const productFactory = new ProductFactory();
const productResponseParser = new ApiCollectionResponseParserService<Product>();

const productRepository = new BasicRepository<Product>(
  "products",
  api,
  productFactory,
  productResponseParser
);

const productAllocationFactory = new ProductAllocationFactory();
const productAllocationResponseParser = new ApiCollectionResponseParserService<ProductAllocation>();

const productAllocationRepository = new ProductAllocationRepository(
  api,
  productAllocationFactory,
  productAllocationResponseParser
);

const productionScheduleFactory = new ProductionScheduleFactory();
const productionScheduleResponseParser = new ApiCollectionResponseParserService<ProductionSchedule>();

const productionScheduleRepository = new BasicRepository<ProductionSchedule>(
  "plushie_production_schedules",
  api,
  productionScheduleFactory,
  productionScheduleResponseParser
);

const productionTimeAlertFactory = new ProductionTimeAlertFactory();
const productionTimeAlertRepository = new ProductionTimeAlertRepository(
  api,
  productionTimeAlertFactory
);

const productionTimeNormFactory = new ProductionTimeNormFactory();
const productionTimeNormResponseParser = new ApiCollectionResponseParserService<ProductionTimeNorm>();

const productionTimeNormRepository = new ProductionTimeNormRepository(
  api,
  productionTimeNormFactory,
  productionTimeNormResponseParser
);

const productionTimeNormHistoryItemFactory = new ProductionTimeNormHistoryItemFactory();
const productionTimeNormHistoryItemResponseParser = new ApiCollectionResponseParserService<ProductionTimeNormHistoryItem>();

const productionTimeNormHistoryItemRepository = new ProductionTimeNormHistoryItemRepository(
  api,
  productionTimeNormHistoryItemFactory,
  productionTimeNormHistoryItemResponseParser
);

const qaDecisionFactory = new QaDecisionFactory();
const qaDecisionResponseParser = new ApiCollectionResponseParserService<QaDecision>();

const qaDecisionRepository = new QaDecisionRepository(
  api,
  qaDecisionFactory,
  qaDecisionResponseParser
);

const qaAssetFactory = new QaAssetFactory();
const qaAssetResponseParser = new ApiCollectionResponseParserService<QaAsset>();

const qaAssetRepository = new QaAssetRepository(
  api,
  qaAssetFactory,
  qaAssetResponseParser
);

const qaRejectionDetailsFactory = new QaRejectionDetailsFactory();
const qaRejectionDetailsResponseParser = new ApiCollectionResponseParserService<QaRejectionDetails>();
const qaRejectionDetailsRepository = new QaRejectionDetailsRepository(
  api,
  qaRejectionDetailsFactory,
  qaRejectionDetailsResponseParser
);

const questionTemplateFactory = new QuestionTemplateFactory();
const questionTemplateResponseParser = new ApiCollectionResponseParserService<QuestionTemplate>();

const questionTemplateRepository = new QuestionTemplateRepository(
  api,
  questionTemplateFactory,
  questionTemplateResponseParser
);

const questionTemplateProductRelationFactory = new QuestionTemplateProductRelationFactory();
const questionTemplateProductRelationResponseParser = new ApiCollectionResponseParserService<QuestionTemplateProductRelation>();

const questionTemplateProductRelationRepository = new QuestionTemplateProductRelationRepository(
  api,
  questionTemplateProductRelationFactory,
  questionTemplateProductRelationResponseParser
);

const questionTemplateStatusRelationFactory = new QuestionTemplateStatusRelationFactory();
const questionTemplateStatusRelationResponseParser = new ApiCollectionResponseParserService<QuestionTemplateStatusRelation>();

const questionTemplateStatusRelationRepository = new QuestionTemplateStatusRelationRepository(
  api,
  questionTemplateStatusRelationFactory,
  questionTemplateStatusRelationResponseParser
);

const rejectionReasonFactory = new RejectionReasonFactory();
const rejectionReasonResponseParser = new ApiCollectionResponseParserService<RejectionReason>();

const rejectionReasonRepository = new RejectionReasonRepository(
  api,
  rejectionReasonFactory,
  rejectionReasonResponseParser
);

const rejectionReasonTypeRepository = new BasicRepository<DirectoryValue>(
  "rejection_reason_types",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const rejectionReasonProductRelationFactory = new RejectionReasonProductRelationFactory();
const rejectionReasonProductRelationResponseParser = new ApiCollectionResponseParserService<RejectionReasonProductRelation>();

const rejectionReasonProductRelationRepository = new RejectionReasonProductRelationRepository(
  api,
  rejectionReasonProductRelationFactory,
  rejectionReasonProductRelationResponseParser
);

const qaDecisionRejectionReasonFactory = new QaDecisionRejectionReasonFactory();
const qaDecisionRejectionReasonResponseParser = new ApiCollectionResponseParserService<QaDecisionRejectionReason>();

const qaDecisionRejectionReasonRepository = new QaDecisionRejectionReasonRepository(
  api,
  qaDecisionRejectionReasonFactory,
  qaDecisionRejectionReasonResponseParser
);

const remotePrintingConfigFactory = new RemotePrintingConfigFactory();
const remotePrintingConfigResponseParser = new ApiCollectionResponseParserService<RemotePrintingConfig>();

const remotePrintingConfigRepository = new RemotePrintingConfigRepository(
  api,
  remotePrintingConfigFactory,
  remotePrintingConfigResponseParser
);

const remotePrintingCredentialsFactory = new RemotePrintingCredentialsFactory();
const remotePrintingCredentialsResponseParser = new ApiCollectionResponseParserService<RemotePrintingCredentials>();

const remotePrintingCredentialsRepository = new RemotePrintingCredentialsRepository(
  api,
  remotePrintingCredentialsFactory,
  remotePrintingCredentialsResponseParser
);

const remotePrinterRepository = new RemotePrinterRepository(api);

const settingFactory = new SettingFactory();

const settingResponseParses = new ApiCollectionResponseParserService<Setting>();

const settingRepository = new SettingRepository(
  api,
  settingFactory,
  settingResponseParses
);

const storeRepository = new BasicRepository<DirectoryValue>(
  "stores",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const tagRepository = new TagRepository(
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const tagAlertAssignRuleFactory = new TagAlertAssignRuleFactory();
const tagAlertAssignRuleResponseParser = new ApiCollectionResponseParserService<TagAlertAssignRule>();

const tagAlertAssignRuleRepository = new TagAlertAssignRuleRepository(
  api,
  tagAlertAssignRuleFactory,
  tagAlertAssignRuleResponseParser
);

const tagSearchResultFactory = new TagSearchResultFactory();

const tagSearchResultRepository = new TagSearchResultRepository(
  api,
  tagSearchResultFactory
);

const upgradeAllocationFactory = new UpgradeAllocationFactory();
const upgradeAllocationResponseParser = new ApiCollectionResponseParserService<UpgradeAllocation>();
const upgradeAllocationRepository = new UpgradeAllocationRepository(
  api,
  upgradeAllocationFactory,
  upgradeAllocationResponseParser
);

const upgradeFactory = new UpgradeFactory();

const upgradeResponseParser = new ApiCollectionResponseParserService<Upgrade>();

const upgradeRepository = new UpgradeRepository(
  api,
  upgradeFactory,
  upgradeResponseParser
);

const userFactoryRelationFactory = new UserFactoryRelationFactory();

const userFactoryRelationResponseParses = new ApiCollectionResponseParserService<UserFactoryRelation>();

const userFactoryRelationRepository = new UserFactoryRelationRepository(
  api,
  userFactoryRelationFactory,
  userFactoryRelationResponseParses
);

const errorConverterService = new ErrorConverterService();

const redirectResolver = new LoginRedirectResolverService();

const userActiveStateProviderFactory = new UserActiveStateProviderFactory(
  window
);
const userActiveStateProvider = userActiveStateProviderFactory.create();

const currentUserStateReporterFactory = new CurrentUserStateReporterFactory(
  userActiveStateProvider,
  window
);

const activeUserFactory = new ActiveUserFactory();

const otherUsersDetectorFactory = new OtherUsersDetectorFactory(
  activeUserFactory,
  eventBus,
  window
);

const userPresenceNotifierFactory = new UserPresenceNotifierFactory(
  currentUserStateReporterFactory,
  otherUsersDetectorFactory,
  authenticatedUserProvider,
  socketServerHost
);

const customerPreviewCountFactory = new PreviewCountFactory();

const customerPreviewCountResponseParser = new ApiCollectionResponseParserService<PreviewCount>();

const customerPreviewCountRepository = new PreviewCountRepository(
  api,
  customerPreviewCountFactory,
  customerPreviewCountResponseParser
);

const autoApprovementExcludeFactory = new AutoApprovementExcludeFactory();
const autoApprovementExcludeResponseParser = new ApiCollectionResponseParserService<AutoApprovementExclude>();
const autoApprovementExcludeRepository = new AutoApprovementExcludeRepository(
  api,
  autoApprovementExcludeFactory,
  autoApprovementExcludeResponseParser
);

const bulkExportAsExcelService = new BulkExportAsExcelService(
  plushieExportJobRepository,
  window
);

const holidayPeriodFactory = new HolidayPeriodFactory();
const holidayPeriodResponseParser = new ApiCollectionResponseParserService<HolidayPeriod>();

const holidayPeriodRepository = new BasicRepository<HolidayPeriod>(
  "holiday_periods",
  api,
  holidayPeriodFactory,
  holidayPeriodResponseParser
);

const holidayPeriodPlushieRelationFactory = new HolidayPeriodPlushieRelationFactory();
const holidayPeriodPlushieRelationResponseParser = new ApiCollectionResponseParserService<HolidayPeriodPlushieRelation>();

const holidayPeriodPlushieRelationRepository = new HolidayPeriodPlushieRelationRepository(
  api,
  holidayPeriodPlushieRelationFactory,
  holidayPeriodPlushieRelationResponseParser
);

const holidayPeriodProductSettingFactory = new HolidayPeriodProductSettingFactory();
const holidayPeriodProductSettingResponseParser = new ApiCollectionResponseParserService<HolidayPeriodProductSetting>();

const holidayPeriodProductSettingRepository = new HolidayPeriodProductSettingRepository(
  api,
  holidayPeriodProductSettingFactory,
  holidayPeriodProductSettingResponseParser
);

const holidayPeriodRegularOrdersAssignJobRepository = new RegularOrdersAssignJobRepository(
  api
);

const cutoffReportDownloadJobRepository = new CutoffReportDownloadJobRepository(
  "holiday_periods/cutoff_report_download_jobs",
  api
);

const futureTurnaroundFactory = new FutureTurnaroundFactory();
const futureTurnaroundRepository = new FutureTurnaroundRepository(
  api,
  futureTurnaroundFactory
);

const qualityInspectionReportFactory = new QualityInspectionReportFactory();
const qualityInspectionReportRepository = new QualityInspectionReportRepository(
  api,
  qualityInspectionReportFactory
);

const reviewerReportFactory = new ReviewerReportFactory();
const reviewerReportRepository = new ReviewerReportRepository(
  api,
  reviewerReportFactory
);

const throughputReportFactory = new ThroughputReportFactory();
const throughputReportRepository = new ThroughputReportRepository(
  api,
  throughputReportFactory
);

const inReviewSnapshotFactory = new InReviewSnapshotFactory();
const inReviewSnapshotRepository = new InReviewSnapshotRepository(
  api,
  inReviewSnapshotFactory
);

const productionSnapshotFactory = new ProductionSnapshotFactory();
const productionSnapshotRepository = new ProductionSnapshotRepository(
  api,
  productionSnapshotFactory
);

const operatorSessionFactory = new OperatorSessionFactory();
const operatorSessionResponseParser = new ApiCollectionResponseParserService<OperatorSession>();
const operatorSessionRepository = new OperatorSessionRepository(
  api,
  operatorSessionFactory,
  operatorSessionResponseParser
);

const projectionFactory = new ProjectionFactory();
const projectionResponseParser = new ApiCollectionResponseParserService<Projection>();
const projectionRepository = new ProjectionRepository(
  api,
  projectionFactory,
  projectionResponseParser
);

const projectionStatsFactory = new ProjectionStatsFactory();
const projectionStatsRepository = new ProjectionStatsRepository(
  api,
  projectionStatsFactory
);

const productionEstimateRepository = new ProductionEstimateRepository(api);

const productionExtendedRepository = new ProductionExtendedRepository(api);

const bulkProjectFactory = new BulkProjectFactory();
const bulkProjectResponseParser = new ApiCollectionResponseParserService<BulkProject>();
const bulkProjectRepository = new BulkProjectRepository(
  api,
  bulkProjectFactory,
  bulkProjectResponseParser
);

const bulkProjectPlushieRelationFactory = new BulkProjectPlushieRelationFactory();
const bulkProjectPlushieRelationResponseParser = new ApiCollectionResponseParserService<BulkProjectPlushieRelation>();
const bulkProjectPlushieRelationRepository = new BulkProjectPlushieRelationRepository(
  api,
  bulkProjectPlushieRelationFactory,
  bulkProjectPlushieRelationResponseParser
);

const bulkProjectPlushieTypeFactory = new BulkProjectPlushieTypeFactory();
const bulkProjectPlushieTypeResponseParser = new ApiCollectionResponseParserService<BulkProjectPlushieType>();
const bulkProjectPlushieTypeRepository = new BulkProjectPlushieTypeRepository(
  api,
  bulkProjectPlushieTypeFactory,
  bulkProjectPlushieTypeResponseParser
);

const bulkProjectBatchInformationFactory = new BulkProjectBatchInformationFactory();
const bulkProjectBatchInformationResponseParser = new ApiCollectionResponseParserService<BulkProjectBatchInformation>();
const bulkProjectBatchInformationRepository = new BulkProjectBatchInformationRepository(
  api,
  bulkProjectBatchInformationFactory,
  bulkProjectBatchInformationResponseParser
);

const bulkProjectStatusRepository = new BasicRepository<DirectoryValue>(
  "bulk_project_statuses",
  api,
  directoryValueFactory,
  directoryValueResponseParser
);

const batchCreationRequestRepository = new BatchCreationRequestRepository(
  api,
  plushieFactory
);

const shippingInformationFactory = new ShippingInformationFactory();
const shippingInformationResponseParser = new ApiCollectionResponseParserService<ShippingInformation>();
const shippingInformationRepository = new ShippingInformationRepository(
  api,
  shippingInformationFactory,
  shippingInformationResponseParser
);

const trackingInformationFactory = new TrackingInformationFactory();
const trackingInformationResponseParser = new ApiCollectionResponseParserService<TrackingInformation>();
const trackingInformationRepository = new TrackingInformationRepository(
  api,
  trackingInformationFactory,
  trackingInformationResponseParser
);

const productionTimeHistoryFactory = new ProductionTimeHistoryFactory();
const productionTimeHistoryResponseParser = new ApiCollectionResponseParserService<ProductionTimeHistory>();

const productionTimeHistoryRepository = new BasicRepository<ProductionTimeHistory>(
  "production_time_histories",
  api,
  productionTimeHistoryFactory,
  productionTimeHistoryResponseParser
);

const directShipmentIntentionFactory = new DirectShipmentIntentionFactory();
const directShipmentIntentionResponseParser = new ApiCollectionResponseParserService<DirectShipmentIntention>();
const directShipmentIntentionRepository = new DirectShipmentIntentionRepository(
  api,
  directShipmentIntentionFactory,
  directShipmentIntentionResponseParser
);

const directShipmentIntentionRuleFactory = new DirectShipmentIntentionRuleFactory();
const directShipmentIntentionRuleResponseParser = new ApiCollectionResponseParserService<DirectShipmentIntentionRule>();
const directShipmentIntentionRuleRepository = new DirectShipmentIntentionRuleRepository(
  api,
  directShipmentIntentionRuleFactory,
  directShipmentIntentionRuleResponseParser
);

const emailLayoutFactory = new EmailLayoutFactory();
const emailLayoutResponseParser = new ApiCollectionResponseParserService<EmailLayout>();
const emailLayoutRepository = new EmailLayoutRepository(
  api,
  emailLayoutFactory,
  emailLayoutResponseParser
);

const emailTemplateFactory = new EmailTemplateFactory();
const emailTemplateResponseParser = new ApiCollectionResponseParserService<EmailTemplate>();
const emailTemplateRepository = new EmailTemplateRepository(
  api,
  emailTemplateFactory,
  emailTemplateResponseParser
);

const senderFactory = new SenderFactory();
const senderResponseParser = new ApiCollectionResponseParserService<Sender>();
const senderRepository = new SenderRepository(
  api,
  senderFactory,
  senderResponseParser
);

const qaPhotoRotationService = new QaPhotoRotationService(api);

export const DIContainer = {
  window: window,
  Router: router,
  EventBus: eventBus,
  IdGenerator: idGenerator,
  ImageHandlerService: imageHandlerService,
  IsUserUnderStrictModeResolver: isUserUnderStrictModeResolver,
  IsPullModeEnabledResolver: isPullModeEnabledResolver,
  BulkExportAsExcelService: bulkExportAsExcelService,
  BulkMoveToDesignService: bulkMoveToDesignService,
  BulkMoveToProductionService: bulkMoveToProductionService,
  BulkPrepareToShipmentService: bulkPrepareToShipmentService,
  BulkReassignToFactoryService: bulkReassignToFactoryService,
  CreateBulkShipmentService: createBulkShipmentService,
  ApiService: api,
  AuthenticatedUserProvider: authenticatedUserProvider,
  AvailableMassActionsProviderService: availableMassActionsProviderService,
  PlushieAlertRepository: plushieAlertRepository,
  PlushieAlertAssignmentRepository: plushieAlertAssignmentRepository,
  PlushieAttachmentDownloaderService: plushieAttachmentDownloaderService,
  PlushieAttachmentRepository: plushieAttachmentRepository,
  PlushieAttachmentTypeRepository: plushieAttachmentTypeRepository,
  AttachmentRepository: attachmentRepository,
  BarcodePrintJobRepository: barcodePrintJobRepository,
  BlockingPlushieRepository: blockingPlushieRepository,
  BodyPartRepository: bodyPartRepository,
  BodyPartValueRepository: bodyPartValueRepository,
  BodyPartValuePlushieRelationRepository: bodyPartValuePlushieRelationRepository,
  BillingInfoRecordRepository: billingInfoRecordRepository,
  BulkShipmentRepository: bulkShipmentRepository,
  BulkShipmentStatusRepository: bulkShipmentStatusRepository,
  BulkShipmentItemRepository: bulkShipmentItemRepository,
  CreatePaymentsService: createPaymentsService,
  DeactivatedUserRepository: deactivatedUserRepository,
  ExcludedProductRepository: excludedProductRepository,
  ExtraPaymentAuthorRepository: extraPaymentAuthorRepository,
  FactoryInvoiceHistoryActionRepository: factoryInvoiceHistoryActionRepository,
  FactoryInvoiceElementTypeRepository: factoryInvoiceElementTypeRepository,
  ElementPriceRepository: elementPriceRepository,
  ElementPriceHistoryRecordRepository: elementPriceHistoryRecordRepository,
  ExtraPaymentFaultRepository: extraPaymentFaultRepository,
  ExtraPaymentRepository: extraPaymentRepository,
  FactoryInvoiceRepository: factoryInvoiceRepository,
  FactoryInvoiceStatusRepository: factoryInvoiceStatusRepository,
  FactoryInvoicesSettingRepository: factoryInvoicesSettingRepository,
  FactoryInvoicingSettingsRepository: factoryInvoicingSettingsRepository,
  FactoryRestrictedModeAlertRepository: factoryRestrictedModeAlertRepository,
  FactoryRestrictedModeConfigRepository: factoryRestrictedModeConfigRepository,
  FactoryRestrictedModeStatusRepository: factoryRestrictedModeStatusRepository,
  FactoryStrictCapacityModeRepository: factoryStrictCapacityModeRepository,
  PlushieQueueControlRepository: plushieQueueControlRepository,
  FactoryPlushieStatusCapacityRepository: factoryPlushieStatusCapacityRepository,
  FactoryPlushieStatusQtyRepository: factoryPlushieStatusQtyRepository,
  CountryRepository: countryRepository,
  CountryRegionRepository: countryRegionRepository,
  ErrorConverterService: errorConverterService,
  ActionLogRecordRepository: actionLogRecordRepository,
  FactoryRepository: factoryRepository,
  FactoryPerformanceRepository: factoryPerformanceRepository,
  FileProcessingRepository: fileProcessingRepository,
  FileStorageItemRepository: fileStorageItemRepository,
  HistoryTypeRepository: historyTypeRepository,
  InvoiceItemRepository: invoiceItemRepository,
  InvoiceItemElementRepository: invoiceItemElementRepository,
  LanguageRepository: languageRepository,
  LabelHeaderRepository: labelHeaderRepository,
  LabelHeaderProductRelationRepository: labelHeaderProductRelationRepository,
  LoginRedirectResolverService: redirectResolver,
  MessagingUserGroupResolverService: messagingUserGroupResolverService,
  MessageRepository: messageRepository,
  MessagingUserGroupRelationRepository: messagingUserGroupRelationRepository,
  MetaRoleRepository: metaRoleRepository,
  MetaRoleResourceRepository: metaRoleResourceRepository,
  OrderInformationRepository: orderInformationRepository,
  OrganizationRepository: organizationRepository,
  OrganizationTypeRepository: organizationTypeRepository,
  PaymentRepository: paymentRepository,
  PenaltyBypassRepository: penaltyBypassRepository,
  PenaltyBypassTypeRepository: penaltyBypassTypeRepository,
  PenaltyRuleRepository: penaltyRuleRepository,
  PenaltyRuleHistoryRecordRepository: penaltyRuleHistoryRecordRepository,
  PermissionResourceRepository: permissionResourceRepository,
  PhotoMarkupRepository: photoMarkupRepository,
  ServiceRepository: serviceRepository,
  PlushieDesignInformationRepository: plushieDesignInformationRepository,
  PlushieFactoryAssignmentRepository: plushieFactoryAssignmentRepository,
  PlushieStorageItemNameResolverService: plushieStorageItemNameResolverService,
  PlushieImageRepository: plushieImageRepository,
  PlushieArbitraryAttributeRepository: plushieArbitraryAttributeRepository,
  PlushiePriorityRepository: plushiePriorityRepository,
  PlushieRepository: plushieRepository,
  PlushieExportJobRepository: plushieExportJobRepository,
  PlushieExportRepository: plushieExportRepository,
  PlushiesGridExportService: plushiesGridExportService,
  PlushieStatusRepository: plushieStatusRepository,
  PlushieTagRelationRepository: plushieTagRelationRepository,
  PlushieUpgradeRelationRepository: plushieUpgradeRelationRepository,
  ProductRepository: productRepository,
  ProductAllocationRepository: productAllocationRepository,
  ProductionProcessRepository: productionProcessRepository,
  StatusSummaryRepository: statusSummaryRepository,
  PlushieStatusPeriodRepository: plushieStatusPeriodRepository,
  ProductionScheduleRepository: productionScheduleRepository,
  ProductionTimeNormRepository: productionTimeNormRepository,
  ProductionTimeNormHistoryItemRepository: productionTimeNormHistoryItemRepository,
  QaDecisionRepository: qaDecisionRepository,
  QaAssetRepository: qaAssetRepository,
  QaRejectionDetailsRepository: qaRejectionDetailsRepository,
  QuestionTemplateRepository: questionTemplateRepository,
  QuestionTemplateProductRelationRepository: questionTemplateProductRelationRepository,
  QuestionTemplateStatusRelationRepository: questionTemplateStatusRelationRepository,
  QuestionTemplateShortContentResolverService: questionTemplateShortContentResolverService,
  RejectionReasonRepository: rejectionReasonRepository,
  RejectionReasonTypeRepository: rejectionReasonTypeRepository,
  RejectionReasonProductRelationRepository: rejectionReasonProductRelationRepository,
  QaDecisionRejectionReasonRepository: qaDecisionRejectionReasonRepository,
  RemotePrintingConfigRepository: remotePrintingConfigRepository,
  RemotePrintingCredentialsRepository: remotePrintingCredentialsRepository,
  RemotePrinterRepository: remotePrinterRepository,
  RoleRepository: roleRepository,
  RoleResourceRepository: roleResourceRepository,
  SettingRepository: settingRepository,
  StoreRepository: storeRepository,
  TagRepository: tagRepository,
  TagSearchResultRepository: tagSearchResultRepository,
  UpgradeAllocationRepository: upgradeAllocationRepository,
  PlushieTagAlertAssignRuleRepository: tagAlertAssignRuleRepository,
  UpgradeRepository: upgradeRepository,
  UserFactoryRelationRepository: userFactoryRelationRepository,
  UserRepository: userRepository,
  UserInfoRepository: userInfoRepository,
  UserRoleRepository: userRoleRepository,
  PlushieSummaryRepository: plushieSummaryRepository,
  PlushieFactorySummaryRepository: plushieFactorySummaryRepository,
  CustomerPreviewCountRepository: customerPreviewCountRepository,
  UserPresenceNotifierFactory: userPresenceNotifierFactory,
  HolidayPeriodRepository: holidayPeriodRepository,
  HolidayPeriodPlushieRelationRepository: holidayPeriodPlushieRelationRepository,
  HolidayPeriodProductSettingRepository: holidayPeriodProductSettingRepository,
  HolidayPeriodRegularOrdersAssignJobRepository: holidayPeriodRegularOrdersAssignJobRepository,
  CutoffReportDownloadJobRepository: cutoffReportDownloadJobRepository,
  FutureTurnaroundRepository: futureTurnaroundRepository,
  QualityInspectionReportRepository: qualityInspectionReportRepository,
  ThroughputReportRepository: throughputReportRepository,
  InReviewSnapshotRepository: inReviewSnapshotRepository,
  ProductionSnapshotRepository: productionSnapshotRepository,
  OperatorSessionRepository: operatorSessionRepository,
  UserActiveStateProvider: userActiveStateProvider,
  ReviewerReportRepository: reviewerReportRepository,
  ProjectionRepository: projectionRepository,
  ProjectionStatsRepository: projectionStatsRepository,
  ProductionEstimateRepository: productionEstimateRepository,
  ProductionExtendedRepository: productionExtendedRepository,
  VideoHandlerService: videoHandlerService,
  VideoDownloaderService: videoDownloaderService,
  ProductionTimeAlertRepository: productionTimeAlertRepository,
  BulkPlushiesArtworksDownloadService: bulkPlushiesArtworksDownloadService,
  BatchCreationRequestRepository: batchCreationRequestRepository,
  BulkProjectRepository: bulkProjectRepository,
  BulkProjectPlushieRelationRepository: bulkProjectPlushieRelationRepository,
  BulkProjectPlushieTypeRepository: bulkProjectPlushieTypeRepository,
  BulkProjectBatchInformationRepository: bulkProjectBatchInformationRepository,
  BulkProjectStatusRepository: bulkProjectStatusRepository,
  ShippingInformationRepository: shippingInformationRepository,
  TrackingInformationRepository: trackingInformationRepository,
  AutoApprovementExcludeRepository: autoApprovementExcludeRepository,
  MessageAttachmentDownloaderService: messageAttachmentDownloaderService,
  MessageAttachmentHandlerService: messageAttachmentHandlerService,
  CopyAttachmentToArtworkRequestRepository: copyAttachmentToArtworkRequestRepository,
  ProductionTimeHistoryRepository: productionTimeHistoryRepository,
  DirectShipmentIntentionRepository: directShipmentIntentionRepository,
  DirectShipmentIntentionRuleRepository: directShipmentIntentionRuleRepository,
  EmailLayoutRepository: emailLayoutRepository,
  EmailTemplateRepository: emailTemplateRepository,
  SenderRepository: senderRepository,
  ArtworksDownloaderService: artworksDownloaderService,
  QaPhotosDownloaderService: qaPhotosDownloaderService,
  CarrierRepository: carrierRepository,
  QaPhotoRotationService: qaPhotoRotationService,
};
