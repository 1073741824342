













import { Component, Prop } from "vue-property-decorator";
import { DialogComponent } from "vue-modal-dialogs";

import Item from "@/modules/file-storage/item.model";

import ImageRotationForm from "./ImageRotationForm.vue";

@Component({
  components: {
    ImageRotationForm,
  },
})
export default class ImageRotationDialog extends DialogComponent<boolean> {
  @Prop({ required: true })
  public readonly rotateAction!: (payload: {
    item: Item;
    rotationDegrees: number;
  }) => Promise<void>;

  @Prop({ required: true })
  public readonly storageItemId!: number;
}
