import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import PlushieImage from "./plushie-image.model";
import PlushieImageType from "./plushie-image-type.model";

export default class PlushieImageRepository extends BasicRepository<PlushieImage> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PlushieImage>,
    responseParser: ApiCollectionResponseParserService<PlushieImage>
  ) {
    super("plushie_images", api, objectBuilder, responseParser);
  }

  public async getByPlushieId(plushieId: string): Promise<PlushieImage[]> {
    const collection = await this.fetchList({ plushie: plushieId });

    return collection.getItems();
  }

  public async createArtwork(
    id: string,
    plushie: string,
    storageItem: string
  ): Promise<PlushieImage> {
    const data = {
      id,
      plushie,
      storageItemId: storageItem,
    };

    const response = await this.api.post(this.resourceName, data);

    return this.objectBuilder.buildFromJSON(response.data);
  }

  public async getMainByPlushieIds(
    plushieIds: string[]
  ): Promise<PlushieImage[]> {
    if (!plushieIds.length) {
      return [];
    }

    const collection = await this.fetchList(
      {
        plushie: plushieIds,
        type: PlushieImageType.MAIN,
      },
      1,
      plushieIds.length
    );

    return collection.getItems();
  }

  public async setAsMain(item: PlushieImage): Promise<PlushieImage[]> {
    const data = {
      id: item.id,
    };

    const response = await this.api.post(this.resourceName + "/main", data);

    const collection = this.responseParser.parse(
      response.data,
      this.objectBuilder
    );

    return collection.getItems();
  }

  public async rotateArtwork({
    id,
    rotationDegrees,
  }: {
    id: string;
    rotationDegrees: number;
  }): Promise<void> {
    await this.api.post(`${this.resourceName}/rotation_requests`, {
      id,
      rotationDegrees,
    });
  }

  protected prepareData(item: PlushieImage): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
    };

    return data;
  }
}
