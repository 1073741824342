





















































import { Component, Prop, Inject, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import FileStorageStore from "@/modules/file-storage/store";
import { Dictionary } from "@/lib/Dictionary.type";
import Item from "@/modules/file-storage/item.model";

@Component({
  components: {
    FormErrors,
    SubmitButton,
  },
})
export default class ImageRotationForm extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly rotateAction!: (payload: {
    item: Item;
    rotationDegrees: number;
  }) => Promise<void>;

  @Prop({ required: true })
  public readonly storageItemId!: string;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  private fRotationDegrees = 0;
  private fFileStorageDataStore: FileStorageStore;

  private readonly fRotationStep = 90;

  public get imageStyle(): Dictionary<string> {
    return { transform: `rotate(${this.fRotationDegrees}deg)` };
  }

  public constructor() {
    super();

    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }

  public getImageThumbnail(size: number, shouldCrop = false): string {
    const storageItem = this.fFileStorageDataStore.getItemById(
      this.storageItemId
    );

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      size,
      size,
      shouldCrop
    );
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  public clockwiseRotate(): void {
    this.addToRotationDegrees(this.fRotationStep);
  }

  public counterClockwiseRotate(): void {
    this.addToRotationDegrees(-this.fRotationStep);
  }

  protected async performSubmit(): Promise<void> {
    if (this.fRotationDegrees === 0) {
      return;
    }

    const item = this.fFileStorageDataStore.getItemById(this.storageItemId);
    if (!item) {
      throw new Error("Storage item is not available!");
    }

    await this.rotateAction({
      item,
      rotationDegrees: this.fRotationDegrees,
    });

    this.$emit("formSubmitted");
  }

  private addToRotationDegrees(degreesToAdd: number): void {
    const newValue = this.fRotationDegrees + degreesToAdd;

    if (Math.abs(newValue) === 360) {
      this.fRotationDegrees = 0;
      return;
    }

    this.fRotationDegrees = newValue;
  }

  @Watch("storageItemIdChange", { immediate: true })
  private _onStorageItemIdChange(): void {
    void this.fFileStorageDataStore.loadItemsByIds([this.storageItemId]);
  }
}
