import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";

import Item from "../item.model";
import { FileProcessingImageType } from "../file-processing-image-type";

const name = "FileStorageStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    item: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class FileStorageStore extends VuexModule {
  item: Dictionary<Item> = {};

  // ################################### ITEMS #########################################

  get getItemById(): (id: string) => Item | undefined {
    return (id: string) => this.item[id];
  }

  get placeholderUrl(): string {
    if (!process.env) {
      throw new Error("Environment is not available!");
    }

    const placeholderKey = process.env.VUE_APP_IMAGE_PLACEHOLDER_KEY;
    if (typeof placeholderKey !== "string") {
      throw new Error("Placeholder url is not defined!");
    }

    return placeholderKey;
  }

  @Mutation
  removeItemById(id: string): void {
    delete this.item[id];
  }

  @Mutation
  updateItem(payload: Item): void {
    Vue.set(this.item, payload.id, payload);
  }

  @Action({ rawError: true })
  async deleteItemById(id: string): Promise<void> {
    await DIContainer.FileStorageItemRepository.deleteById(id);

    this.removeItemById(id);

    return;
  }

  @Action({ rawError: true })
  async loadItemById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache: boolean;
  }): Promise<Item | undefined> {
    if (useCache && this.item[id]) {
      return this.item[id];
    }

    const result = await DIContainer.FileStorageItemRepository.getList(1, 1, {
      id,
    });

    const item = result.getById(id);

    if (item) {
      this.updateItem(item);
    }

    return item;
  }

  @Action({ rawError: true })
  async loadItemsByIds(ids: string[]): Promise<Dictionary<Item | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<Item | undefined> = {};

    ids.forEach((id) => {
      if (!this.item[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.item[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.FileStorageItemRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updateItem(item);
    });

    return { ...result, ...items };
  }

  @Action({ rawError: true })
  async uploadFile({
    file,
    imageType,
    product,
    onUploadProgress,
  }: {
    file: File;
    imageType: Exclude<
      FileProcessingImageType,
      FileProcessingImageType.QaVideo | FileProcessingImageType.QaVideoThumb
    >;
    product?: string;
    onUploadProgress?: (progressEvent: ProgressEvent) => void;
  }): Promise<Item> {
    const item = await DIContainer.FileProcessingRepository.uploadFile(
      file,
      imageType,
      product,
      onUploadProgress
    );

    this.updateItem(item);

    return item;
  }

  @Action({ rawError: true })
  async uploadVideoFile({
    file,
    product,
    onUploadProgress,
  }: {
    file: File;
    product?: string;
    onUploadProgress?: (progressEvent: ProgressEvent) => void;
  }): Promise<{
    item: Item;
    itemThumb: Item;
  }> {
    const {
      item,
      itemThumb,
    } = await DIContainer.FileProcessingRepository.uploadVideoFile(
      file,
      product,
      onUploadProgress
    );

    this.updateItem(item);
    this.updateItem(itemThumb);

    return { item, itemThumb };
  }

  // ################################### EVENTS #########################################

  @Action({ rawError: true })
  async onImageRotated({ itemId }: { itemId: string }): Promise<void> {
    await this.loadItemById({ id: itemId, useCache: false });
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
