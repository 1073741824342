




























































































import { Component, Prop, Inject, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { create } from "vue-modal-dialogs";
import insertText from "insert-text-textarea";
import { getModule } from "vuex-module-decorators";
import autosize from "v-autosize";

import ColorsAutocomplete from "@/lib/components/ColorsAutocomplete.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import Item from "@/modules/file-storage/item.model";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import autoindent from "@/lib/directives/auto-indent.directive";
import preloadImage from "@/lib/preload-image.function";
import PlushieStatus from "@/modules/plushie/plushie-status.model";
import Plushie from "@/modules/plushie/plushie.model";
import { UiPersisterStore } from "@/modules/ui-persister/store";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";

import MessagesSendingFormEmojiPicker from "./EmojiPicker.vue";
import MessagingQuestionTemplates from "./MessageSendingForm/QuestionTemplates.vue";
import MessageAttachmentUpload from "./MessageSendingForm/AttachmentUpload.vue";

import { ChannelValue } from "../channel.value";
import MessagingStore from "../store";
import getAttachmentThumbnail from "../get-attachment-thumbnail.function";

@Component({
  components: {
    ColorsAutocomplete,
    FormField,
    FormErrors,
    MessageAttachmentUpload,
    SubmitButton,
    MessagingQuestionTemplates,
    MessagesSendingFormEmojiPicker,
  },
  directives: {
    autoindent,
    autosize,
  },
})
export default class MessageSendingForm extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  public channel?: string;

  private fAttachmentStorageItemsData: Pick<Item, "mime" | "id">[] = [];
  private fIsAttachmentsProcessingInProgress = false;

  private fMessagingDataStore: MessagingStore;
  private fPlushieDataStore: PlushieStore;
  private fUiPersisterDataStore: UiPersisterStore;

  get message(): string {
    return this.fUiPersisterDataStore.getPlushieMessage(this.plushieId) || "";
  }

  set message(message: string) {
    this.fUiPersisterDataStore.updatePlushieMessage({
      plushieId: this.plushieId,
      message: message,
    });
  }

  get channelOptions(): { id: string; name: string }[] {
    const result: { id: string; name: string }[] = [];

    if (!this.plushie) {
      return result;
    }

    if (
      PlushieStatus.PRODUCTION_STATUSES.includes(this.plushie.status) ||
      this.plushie.status === PlushieStatusValue.SENT_TO_CUSTOMER
    ) {
      result.push({ id: ChannelValue.STORE_FACTORY, name: "Factory" });
    }

    if (this.showChannelsSelect) {
      result.push(
        { id: ChannelValue.STORE_CUSTOMER, name: "Customer" },
        { id: ChannelValue.PRIVATE, name: "Private" }
      );
    }

    return result;
  }

  get showCannedResponse(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return [
      AuthenticatedUser.ROLE_ADMIN,
      AuthenticatedUser.ROLE_STORE,
    ].includes(user.role);
  }

  get showChannelsSelect(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return [
      AuthenticatedUser.ROLE_ADMIN,
      AuthenticatedUser.ROLE_STORE,
    ].includes(user.role);
  }

  get isAttachmentsProcessingInProgress(): boolean {
    return this.fIsAttachmentsProcessingInProgress;
  }

  get isDisabled(): boolean {
    return (
      this.isAttachmentsProcessingInProgress || this.fIsDisabled || this.disable
    );
  }

  get isUploadDisabled(): boolean {
    return this.fIsDisabled || this.disable;
  }

  get plushie(): Plushie | undefined {
    return this.fPlushieDataStore.getPlushieById(this.plushieId);
  }

  constructor() {
    super();
    this.fMessagingDataStore = getModule(MessagingStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fUiPersisterDataStore = getModule(UiPersisterStore, dataStore);
  }

  public browseFiles(): void {
    const attachmentUpload = this.getAttachmentUpload();
    if (!attachmentUpload) {
      return;
    }

    attachmentUpload.browseFiles();
  }

  onEmojiPicked(emoji: string): void {
    const messageInput = this.getMessageInput();

    if (!messageInput) {
      return;
    }

    messageInput.focus();

    insertText(messageInput, emoji);
  }

  async showQuestionTemplates(): Promise<void> {
    if (!this.plushie) {
      return;
    }

    const messageInput = this.getMessageInput();
    if (!messageInput) {
      return;
    }

    const dialogFunction = create<Record<string, unknown>, string>(
      MessagingQuestionTemplates
    );

    const templateId = await dialogFunction({
      statusId: this.plushie.status,
      productId: this.plushie.product,
    });

    const template = this.fMessagingDataStore.getQuestionTemplateById(
      templateId
    );

    if (!template) {
      return;
    }

    messageInput.focus();

    insertText(messageInput, (this.message ? "\n" : "") + template.content);
  }

  public onAttachmentAdded(storageItem: Item): void {
    this.fAttachmentStorageItemsData.push({
      id: storageItem.id,
      mime: storageItem.mime,
    });

    const thumbUrl = getAttachmentThumbnail(
      storageItem.url,
      this.fImageHandlerService
    );

    void preloadImage(thumbUrl);
  }

  public onAttachmentRemoved(storageItemId: string): void {
    const index = this.fAttachmentStorageItemsData.findIndex(
      (item) => item.id === storageItemId
    );

    if (index > -1) {
      this.fAttachmentStorageItemsData.splice(index, 1);
    }
  }

  public onAttachmentIsBusyChanged(value: boolean): void {
    this.fIsAttachmentsProcessingInProgress = value;
  }

  protected async afterSubmit(): Promise<void> {
    this.$nextTick(() => {
      const messageInput = this.getMessageInput();
      if (!messageInput) {
        return;
      }

      messageInput.focus();
    });
  }

  protected created(): void {
    if (this.channelOptions.length === 1) {
      this.channel = this.channelOptions[0].id;
    }
  }

  protected data(): Record<string, unknown> {
    return {
      channel: this.channel,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.message || !this.channel) {
      throw new Error("Not all required params are specified");
    }

    await this.fMessagingDataStore.createMessage({
      id: this.fIdGenerator.getId(),
      channel: this.channel,
      plushie: this.plushieId,
      text: this.message,
      attachments: this.fAttachmentStorageItemsData,
    });

    this.fUiPersisterDataStore.deletePlushieMessage({
      plushieId: this.plushieId,
    });

    const attachmentUpload = this.getAttachmentUpload();
    if (!attachmentUpload) {
      return;
    }

    this.fAttachmentStorageItemsData = [];
    attachmentUpload.clearInput();
  }

  private getAttachmentUpload(): MessageAttachmentUpload | undefined {
    return this.$refs["attachment-upload"] as MessageAttachmentUpload;
  }

  private getMessageInput(): HTMLTextAreaElement | undefined {
    return this.$refs["message-input"] as HTMLTextAreaElement;
  }

  private async getPlushieData(plushieId: string): Promise<void> {
    await this.fPlushieDataStore.loadPlushieById({
      id: plushieId,
    });

    await this.loadQuestionTemplates();
  }

  private async loadQuestionTemplates(): Promise<void> {
    if (!this.plushie || !this.showCannedResponse) {
      return;
    }

    const [
      questionTemplateStatusRelations,
      questionTemplateProductRelations,
    ] = await Promise.all([
      this.fMessagingDataStore.loadQuestionTemplateStatusRelationsByStatusId({
        statusId: this.plushie.status,
      }),
      this.fMessagingDataStore.loadQuestionTemplateProductRelationsByProductId({
        productId: this.plushie.product,
      }),
    ]);

    const questionTemplateIds = questionTemplateStatusRelations.map(
      (relation) => relation.template
    );

    const productTemplateIds = questionTemplateProductRelations.map(
      (relation) => relation.template
    );

    await this.fMessagingDataStore.loadQuestionTemplatesByIds({
      ids: [...questionTemplateIds, ...productTemplateIds],
    });
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange(): Promise<void> {
    if (!this.plushieId) {
      return;
    }

    await this.getPlushieData(this.plushieId);

    await this.$validator.reset();
  }
}
