









































import "v-autocomplete/dist/v-autocomplete.css";

import { Component, Prop, Vue } from "vue-property-decorator";
import Autocomplete from "v-autocomplete";

import SearchSelectItem from "./SearchSelectItem.vue";

import { SearchSelectOption } from "../interfaces/search-select-option.interface";

@Component({
  components: {
    Autocomplete,
  },
})
export default class SearchSelect extends Vue {
  @Prop({ default: () => [] })
  public readonly value!: SearchSelectOption[];

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Prop({ default: () => [] })
  public readonly options!: SearchSelectOption[];

  @Prop({ default: "" })
  public readonly placeholder!: string;

  public readonly componentItem = SearchSelectItem;

  private fFilteredOptions: SearchSelectOption[] = [];

  get filteredOptions(): SearchSelectOption[] {
    return this.fFilteredOptions;
  }

  public onInput(value: SearchSelectOption): void {
    if (!value) {
      return;
    }

    if (this.value.find(({ id }) => value.id === id)) {
      return;
    }

    const newValue = [...this.value, value];
    this.$emit("input", newValue);
  }

  public onChange(text: string): void {
    const lowerText = text.toLowerCase();
    this.fFilteredOptions = this.options.filter((item) =>
      item.label.toLowerCase().includes(lowerText)
    );
  }

  public removeSelectedOption(option: SearchSelectOption): void {
    const newValue = this.value.filter((item) => item.id !== option.id);
    this.$emit("input", newValue);
  }
}
