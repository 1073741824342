






















































import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";

import FactoryInvoiceStore from "../../store/index";
import FactoryInvoice from "../../factory-invoice.model";
import InvoiceItem from "../../invoice-item.model";
import { InvoiceElementTypeValue } from "../../invoice-item-element-type.value";

interface SummaryElement {
  id: string;
  name: string;
  sn: number;
  qty: number;
}

@Component({})
export default class FactoryInvoiceViewSummary extends Vue {
  @Prop({ required: true })
  public readonly invoiceId!: string;

  @Prop({ required: true })
  public readonly isFactoryMode!: boolean;

  private fFactoryInvoiceStore: FactoryInvoiceStore;

  get costOfGoodsTotal(): number | undefined {
    return this.invoice?.costOfGoodsTotal;
  }

  get designServicesTotal(): number | undefined {
    return this.invoice?.designServicesTotal;
  }

  get invoice(): FactoryInvoice | undefined {
    return this.fFactoryInvoiceStore.getFactoryInvoiceById(this.invoiceId);
  }

  get items(): InvoiceItem[] {
    return this.fFactoryInvoiceStore.getInvoiceItemsByInvoiceId(this.invoiceId);
  }

  get summaryElements(): SummaryElement[] {
    const itemIds = this.items.map((item) => item.id);

    const summaryElements: Dictionary<SummaryElement> = {};

    itemIds.forEach((itemId) => {
      const itemElements = this.fFactoryInvoiceStore.getInvoiceItemElementsByItemId(
        itemId
      );

      for (const element of itemElements) {
        if (this.isFactoryMode && element.price === 0) {
          continue;
        }

        if (!summaryElements[element.entity]) {
          summaryElements[element.entity] = {
            id: element.id,
            name: element.name,
            sn: element.type === InvoiceElementTypeValue.PRODUCT ? 0 : 1,
            qty: 0,
          };
        }

        summaryElements[element.entity].qty += element.qty;
      }
    });

    const result = Object.values(summaryElements);

    result.sort((a, b) =>
      a.sn > b.sn || a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );

    return result;
  }

  constructor() {
    super();

    this.fFactoryInvoiceStore = getModule(FactoryInvoiceStore, dataStore);
  }
}
