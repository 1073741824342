import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import FactoryInvoice from "./factory-invoice.model";

export default class FactoryInvoiceFactory
  implements ObjectBuilderInterface<FactoryInvoice> {
  public buildFromJSON(data: { [key: string]: any }): FactoryInvoice {
    const value = new FactoryInvoice(
      data.id,
      data.incrementalId,
      data.factoryId,
      data.userId,
      data.status,
      +data.subtotal,
      +data.total,
      +data.vendorAdjustments,
      +data.buyerAdjustments,
      +data.itemsQty,
      +data.totalExtraPyment,
      +data.budsiesFaultExtraPyment,
      +data.customerFaultExtraPyment,
      data.hasUnpricedElements,
      data.shipmentId,
      +data.designServicesTotal,
      +data.costOfGoodsTotal
    );

    (value as any).fVendorNotes = data.vendorNotes;
    (value as any).fBuyerNotes = data.buyerNotes;
    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
