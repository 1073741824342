import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import FactoryInvoicingSettings from "./factory-invoicing-settings.model";

export default class FactoryInvoicingSettingsRepository extends BasicRepository<FactoryInvoicingSettings> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<FactoryInvoicingSettings>,
    responseParser: ApiCollectionResponseParserService<FactoryInvoicingSettings>
  ) {
    super("factory_invoicing_settings", api, objectBuilder, responseParser);
  }

  public async fetchByFactoryId(
    factoryId: string
  ): Promise<FactoryInvoicingSettings | undefined> {
    const result = await this.getById(factoryId);

    return result;
  }

  protected prepareData(
    item: FactoryInvoicingSettings
  ): Dictionary<string | number | boolean> {
    const data: Dictionary<string | number | boolean> = {
      factoryId: item.factoryId,
      invoicingModel: item.invoicingModel,
      ignoreQuantity: item.isIgnoreQuantity,
      invoicingPeriod: Number(item.invoicingPeriod),
      designServicesRatio: item.designServicesRatio || 0,
    };

    return data;
  }
}
