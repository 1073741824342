
























































































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import rootStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import IdGenerator from "@/lib/services/id-generator";

import PlushieStore from "../../store";
import Upgrade from "../../upgrade.model";

@Component({
  components: {
    FormField,
    FormErrors,
    LoadingSpinner,
    SubmitButton,
  },
})
export default class UpgradeManageForm extends mixins(GeneralFormMixin) {
  @Prop()
  public readonly upgradeId: string | undefined;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public code?: string;
  public title?: string;
  public shortName?: string;
  public displayToFactory = false;

  private fPlushieStore: PlushieStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get existingUpgrade(): Upgrade | undefined {
    if (!this.upgradeId) {
      return;
    }

    return this.fPlushieStore.upgrade[this.upgradeId];
  }

  get submitButtonText(): string {
    return this.existingUpgrade ? "Save" : "Add";
  }

  public constructor() {
    super();

    this.fPlushieStore = getModule(PlushieStore, rootStore);
  }

  protected data(): Record<string, string | undefined> {
    return {
      code: this.code,
      title: this.title,
      shortName: this.shortName,
    };
  }

  protected async performSubmit(): Promise<void> {
    const upgrade = this.generateUpgradeObject();

    const result = await this.fPlushieStore.saveUpgrade(upgrade);

    if (!result) {
      return;
    }

    this.$emit("completed");
  }

  private fillExistingUpgradeData(upgrade: Upgrade): void {
    this.code = upgrade.code;
    this.title = upgrade.name;
    this.shortName = upgrade.shortName;
    this.displayToFactory = upgrade.displayToFactory;
  }

  private generateUpgradeObject(): Upgrade {
    if (!this.code || !this.title || !this.shortName) {
      throw new Error(
        "Unable to create object - some required fields are missing!"
      );
    }

    const id = this.existingUpgrade?.id
      ? this.existingUpgrade.id
      : this.fIdGenerator.getId();

    const value = new Upgrade(
      id,
      this.code,
      this.title,
      this.displayToFactory,
      true
    );

    (value as any).fShortName = this.shortName;

    if (this.existingUpgrade) {
      value.isNew = false;
    }

    return value;
  }

  private async loadUpgrade(upgradeId: string): Promise<Upgrade | undefined> {
    this.fIsLoading = true;
    const upgrade = await this.fPlushieStore.loadUpgradeById(upgradeId);

    if (!upgrade) {
      return;
    }

    this.fIsLoading = false;
    return upgrade;
  }

  private resetForm(): void {
    this.code = undefined;
    this.title = undefined;
    this.shortName = undefined;
    this.displayToFactory = false;
  }

  @Watch("upgradeId", { immediate: true })
  private async _onUpgradeIdChanged(value?: string): Promise<void> {
    if (!value) {
      this.resetForm();
      return;
    }

    const upgrade = await this.loadUpgrade(value);

    if (!upgrade) {
      this.resetForm();
      return;
    }

    this.fillExistingUpgradeData(upgrade);
  }
}
