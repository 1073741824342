












import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import ProductionProcessStore from "@/modules/production-process/store";
import { CommandCode } from "@/modules/production-process/command-code";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import Command from "../command.model";

@Component({
  components: {
    FormErrors,
    SubmitButton,
  },
})
export default class BasicCommand extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly commandCode!: CommandCode;

  @Prop({ required: true })
  public readonly plushieId!: string;

  private fProductionProcessDataStore: ProductionProcessStore;

  get buttonText(): string {
    return this.command?.commandName || "";
  }

  get command(): Command | undefined {
    return this.fProductionProcessDataStore.getCommandByCode(this.commandCode);
  }

  constructor() {
    super();
    this.fProductionProcessDataStore = getModule(
      ProductionProcessStore,
      dataStore
    );
  }

  protected async performSubmit(): Promise<void> {
    if (!this.command) {
      throw new Error(
        `Command is not defined for command code: ${this.commandCode}`
      );
    }

    // This command return different response than other commands
    if (this.command.code === CommandCode.ApproveCustomerPreview) {
      await this.fProductionProcessDataStore.approveCustomerPreview(
        this.plushieId
      );
      return;
    }

    await this.fProductionProcessDataStore.processBasicCommand({
      command: this.command,
      plushieId: this.plushieId,
    });
  }
}
