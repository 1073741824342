import { AxiosResponse } from "axios";

import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import Plushie from "@/modules/plushie/plushie.model";
import { ResourceCollection } from "@/modules/api/resource.collection";

import { CommandCode } from "./command-code";
import Command from "./command.model";

export default class ProductionProcessRepository {
  constructor(
    private api: ApiService,
    private plushieBuilder: ObjectBuilderInterface<Plushie>,
    private plushieResponseParser: ApiCollectionResponseParserService<Plushie>
  ) {}

  public async getAvailableCommands(
    plushieId: string
  ): Promise<Record<CommandCode, Command>> {
    if (!plushieId) {
      throw new Error("Plushie Id is required!");
    }

    const response = await this.api.get(`plushies/${plushieId}/commands`);
    const dictionary: Record<string, Command> = {};

    for (const key in response.data) {
      const data: Record<string, string | boolean> = response.data[key];

      dictionary[key] = new Command(
        key as CommandCode,
        data.commandName as string,
        data.routePath as string,
        data.isBasic as boolean
      );
    }

    return dictionary;
  }

  public async processBasicCommand(
    command: Command,
    plushieId: string
  ): Promise<Plushie> {
    const result = await this.api.post(command.routePath, [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async approveCustomerPreview(
    plushieId: string
  ): Promise<AxiosResponse> {
    return this.api.post("customer_previews/approvements", {
      plushieId,
    });
  }

  public async cancelPlushie(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/canceled", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async bulkMoveToDesign(
    plushieIds: string[]
  ): Promise<ResourceCollection<Plushie>> {
    const data = plushieIds.map((plushie) => ({
      plushie,
    }));

    const response = await this.api.post("plushies/in_design", data);

    return this.plushieResponseParser.parse(response.data, this.plushieBuilder);
  }

  public async bulkMoveToProduction(
    plushieIds: string[]
  ): Promise<ResourceCollection<Plushie>> {
    const data = plushieIds.map((plushie) => ({
      plushie,
    }));

    const response = await this.api.post("plushies/in_production", data);

    return this.plushieResponseParser.parse(response.data, this.plushieBuilder);
  }

  public async bulkPrepareToShipment(
    plushieIds: string[]
  ): Promise<ResourceCollection<Plushie>> {
    const data = plushieIds.map((plushie) => ({
      plushie,
    }));

    const response = await this.api.post("plushies/ready_for_shipment", data);

    return this.plushieResponseParser.parse(response.data, this.plushieBuilder);
  }

  public async moveToQualityInspection(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/in_quality_inspection", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async reassignFactory(
    plushieId: string,
    newFactoryId: string
  ): Promise<Plushie> {
    const result = await this.api.post("plushie_factory_relations", [
      { plushie: plushieId, factory: newFactoryId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async bulkReassignFactory(
    plushieIds: string[],
    factory: string
  ): Promise<ResourceCollection<Plushie>> {
    const data = plushieIds.map((plushie) => ({
      plushie,
      factory,
    }));

    const response = await this.api.post("plushie_factory_relations", data);

    return this.plushieResponseParser.parse(response.data, this.plushieBuilder);
  }

  public async reviewApprove(
    plushieId: string,
    comment?: string
  ): Promise<Plushie> {
    const result = await this.api.post("plushies/review_approved", [
      { plushie: plushieId, comment: comment ?? "" },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async reviewApproveAndSkipDesign(
    plushieId: string,
    comment?: string
  ): Promise<Plushie> {
    const result = await this.api.post(
      "plushies/review_approved_and_skip_design",
      [{ plushie: plushieId, comment: comment ?? "" }]
    );

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  // Batches commands
  public async confirmBulkShipment(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/bulk_shipment_confirmed", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async moveToBulkInspection(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/in_bulk_inspection", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async moveToPpsInspection(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/in_pps_inspection", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async sendToCustomer(
    plushieId: string,
    carrierId: string,
    trackingNumber?: string
  ): Promise<Plushie> {
    const result = await this.api.post("plushies/sent_to_customer", [
      { plushie: plushieId, trackingNumber, carrierId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async startSampleProduction(payload: {
    plushie: string;
    factoryIdToAssign: string;
    taskDetails: string;
    expectedDueDate: string;
    negotiatedPrice: number;
  }): Promise<Plushie> {
    const result = await this.api.post("plushies/in_bulk_design", [payload]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }
}
