export enum CommandCode {
  // TODO: This command is basic, but API response is different.
  // So generic action for basic commands can't be used.
  ApproveCustomerPreview = "approve_customer_preview",
  ApproveQualityInspection = "approve_quality_inspection",
  ApproveReview = "approve_review",
  ApproveReviewAndSkipDesign = "approve_review_and_skip_design",
  CancelPlushie = "cancel_plushie",
  MoveToQualityInspection = "move_to_quality_inspection",
  PrepareToProduction = "prepare_to_production",
  ReassignFactory = "reassign_factory",
  ReturnToCustomerPreviewApproved = "return_to_customer_preview_approved",
  RejectQualityInspection = "reject_quality_inspection",
  SendToCustomer = "send_to_customer",
  ShipToMainFacility = "ship_to_main_facility",
  UndoCustomerPreviewApprove = "undo_customer_preview_approve",

  // Batches commands
  ApproveBulkInspection = "approve_bulk_inspection",
  ApprovePpsInspection = "approve_pps_inspection",
  ConfirmBulkShipment = "confirm_bulk_shipment",
  MoveToBulkInspection = "move_to_bulk_inspection",
  MoveToPpsInspection = "move_to_pps_inspection",
  RejectBulkInspection = "reject_bulk_inspection",
  RejectBulkPreview = "reject_bulk_preview",
  RejectPpsInspection = "reject_pps_inspection",
  RejectPpsPreview = "reject_pps_preview",
  StartSampleProduction = "start_sample_production",
}
