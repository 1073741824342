import { MutableValue } from "@/modules/api/mutable-value.interface";

import { FactoryInvoiceStatusValue } from "./factory-invoice-status.value";

export default class FactoryInvoice implements MutableValue {
  public isNew = true;

  private fVendorNotes?: string;
  private fBuyerNotes?: string;
  private fCreatedAt?: Date;

  constructor(
    private fId: string,
    private fNumber: string,
    private fFactory: string,
    private fUser: string,
    private fStatus: FactoryInvoiceStatusValue,
    private fSubtotal: number,
    private fTotal: number,
    private fVendorAdjustments: number,
    private fBuyerAdjustments: number,
    private fItemsQty: number,
    private fTotalExtraPayment: number,
    private fBudsiesFaultExtraPayment: number,
    private fCustomerFaultExtraPayment: number,
    private fHasUnpricedElements: boolean,
    private fShipment?: string,
    private fDesignServicesTotal?: number,
    private fCostOfGoodsTotal?: number
  ) {
    [
      fId,
      fShipment,
      fFactory,
      fUser,
      fStatus,
      fSubtotal,
      fTotal,
      fVendorAdjustments,
      fBuyerAdjustments,
      fItemsQty,
      fTotalExtraPayment,
      fBudsiesFaultExtraPayment,
      fCustomerFaultExtraPayment,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get shipment(): string | undefined {
    return this.fShipment;
  }

  get factory(): string {
    return this.fFactory;
  }

  get number(): string {
    return this.fNumber;
  }

  get status(): FactoryInvoiceStatusValue {
    return this.fStatus;
  }

  get subtotal(): number {
    return this.fSubtotal;
  }

  get total(): number {
    return this.fTotal;
  }

  get user(): string {
    return this.fUser;
  }

  get vendorAdjustments(): number {
    return this.fVendorAdjustments;
  }

  get buyerAdjustments(): number {
    return this.fBuyerAdjustments;
  }

  get itemsQty(): number {
    return this.fItemsQty;
  }

  get totalExtraPayment(): number {
    return this.fTotalExtraPayment;
  }

  get budsiesFaultExtraPayment(): number {
    return this.fBudsiesFaultExtraPayment;
  }

  get customerFaultExtraPayment(): number {
    return this.fCustomerFaultExtraPayment;
  }

  get vendorNotes(): string | undefined {
    return this.fVendorNotes;
  }

  get buyerNotes(): string | undefined {
    return this.fBuyerNotes;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get hasUnpricedElements(): boolean {
    return this.fHasUnpricedElements;
  }

  get designServicesTotal(): number | undefined {
    return this.fDesignServicesTotal;
  }

  get costOfGoodsTotal(): number | undefined {
    return this.fCostOfGoodsTotal;
  }
}
