import { AxiosResponse } from "axios";

import ApiService from "../api/api.service";

export default class QaPhotoRotationService {
  private readonly fResourceName = "qa_photos";

  constructor(protected api: ApiService) {}

  public async rotatePhoto(
    id: string,
    rotationDegrees: number
  ): Promise<AxiosResponse> {
    return this.api.post(`${this.fResourceName}/rotation_requests`, {
      id,
      rotationDegrees,
    });
  }
}
