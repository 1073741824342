import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import dataStore from "@/store";
import { ResourceCollection } from "@/modules/api/resource.collection";

import RejectionReason from "../rejection-reason.model";
import RejectionReasonProductRelation from "../rejection-reason-product-relation.model";
import QaAsset from "../qa-asset.model";
import QaDecision from "../qa-decision.model";
import QaAssetType from "../qa-asset-type";
import { QaPhotoAngle } from "../qa-photo-angle";
import QaRejectionDetails from "../qa-rejection-details.model";

const name = "QualityInspectionStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    qaAsset: {},
    rejectionReason: {},
    rejectionReasonType: {},
    rejectionReasonProductRelation: {},
    plushieQaAsset: {},
    productRelations: {},
    reasonRelations: {},
    rejection: {},
    plushieLastRejection: {},
    rejectionDetails: {},
    storageItemQaAsset: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class QualityInspectionStore extends VuexModule {
  qaAsset: Dictionary<QaAsset> = {};
  rejectionReason: Dictionary<RejectionReason> = {};
  rejectionReasonType: Dictionary<DirectoryValue> = {};
  rejectionReasonProductRelation: Dictionary<RejectionReasonProductRelation> = {};

  plushieQaAsset: Dictionary<string[]> = {};
  storageItemQaAsset: Dictionary<string> = {};
  productRelations: Dictionary<string[]> = {};
  reasonRelations: Dictionary<string[]> = {};

  rejection: Dictionary<QaDecision> = {};
  plushieLastRejection: Dictionary<string> = {};

  rejectionDetails: Dictionary<QaRejectionDetails> = {};
  // ################################### QA ASSETS #########################################

  get getQaAssetById(): (id: string) => QaAsset | undefined {
    return (id: string) => this.qaAsset[id];
  }

  get getQaAssetsByPlushieId(): (plushieId: string) => QaAsset[] {
    return (plushieId: string) => {
      const qaAssetsIds = this.plushieQaAsset[plushieId];

      if (!qaAssetsIds) {
        return [];
      }

      const result: QaAsset[] = [];

      qaAssetsIds.forEach((id) => {
        const image = this.qaAsset[id];
        if (!image) {
          return;
        }

        result.push(image);
      });

      result.sort((a, b) => {
        if (a.createdAt === b.createdAt) {
          return a.id < b.id ? 1 : -1;
        }

        return (a.createdAt || -1) < (b.createdAt || -1) ? 1 : -1;
      });

      return result;
    };
  }

  get getQaAssetByStorageItemId(): (
    storageItemId: string
  ) => QaAsset | undefined {
    return (storageItemId: string) => {
      const qaAssetId = this.storageItemQaAsset[storageItemId];

      if (!qaAssetId) {
        return;
      }

      return this.qaAsset[qaAssetId];
    };
  }

  @Mutation
  removeQaAsset(payload: QaAsset): void {
    const plushieQaAssets = this.plushieQaAsset[payload.plushie];
    let index = -1;

    if (plushieQaAssets) {
      index = plushieQaAssets.indexOf(payload.id);
    }

    if (index !== -1) {
      plushieQaAssets.splice(index, 1);
    }

    delete this.qaAsset[payload.id];
    Vue.delete(this.storageItemQaAsset, payload.storageItem);
  }

  @Mutation
  updateQaAsset(payload: QaAsset): void {
    Vue.set(this.qaAsset, payload.id, payload);

    if (this.plushieQaAsset[payload.plushie] === undefined) {
      return;
    }

    if (!this.plushieQaAsset[payload.plushie].includes(payload.id)) {
      this.plushieQaAsset[payload.plushie].push(payload.id);
    }

    Vue.set(this.storageItemQaAsset, payload.storageItem, payload.id);
  }

  @Mutation
  updateQaAssets({
    plushieId,
    qaAssets,
  }: {
    plushieId: string;
    qaAssets: QaAsset[];
  }): void {
    qaAssets.forEach((qaAsset) => {
      if (qaAsset.plushie !== plushieId) {
        throw new Error(
          "All qa assets should belong to the specified plushie!"
        );
      }
    });

    const plushieQaAssets: string[] = [];

    qaAssets.forEach((qaAsset) => {
      plushieQaAssets.push(qaAsset.id);
      Vue.set(this.qaAsset, qaAsset.id, qaAsset);
      Vue.set(this.storageItemQaAsset, qaAsset.storageItem, qaAsset.id);
    });

    Vue.set(this.plushieQaAsset, plushieId, plushieQaAssets);
  }

  @Action({ rawError: true })
  async createQaAsset({
    id,
    plushie,
    type,
    storageItem,
    angle,
    thumbnailStorageItemId,
  }: {
    id: string;
    plushie: string;
    type: QaAssetType;
    storageItem: string;
    angle?: QaPhotoAngle;
    thumbnailStorageItemId?: string;
  }): Promise<QaAsset> {
    const item = await DIContainer.QaAssetRepository.createQaAsset(
      id,
      plushie,
      type,
      storageItem,
      angle,
      thumbnailStorageItemId
    );

    this.updateQaAsset(item);

    if (
      this.plushieQaAsset[item.plushie] &&
      this.plushieQaAsset[item.plushie].length === 1
    ) {
      void this.context.dispatch("onFirstQaAssetAdded", item.plushie);
    }

    return item;
  }

  @Action({ rawError: true })
  async deleteQaAsset(qaAsset: QaAsset): Promise<void> {
    await DIContainer.QaAssetRepository.delete(qaAsset);

    this.removeQaAsset(qaAsset);

    if (
      this.plushieQaAsset[qaAsset.plushie] &&
      this.plushieQaAsset[qaAsset.plushie].length === 0
    ) {
      void this.context.dispatch("onLastQaAssetRemoved", qaAsset.plushie);
    }

    return;
  }

  @Action({ rawError: true })
  async loadQaAssetByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<QaAsset[]> {
    let qaAssets: QaAsset[] = [];

    if (useCache && this.plushieQaAsset[plushieId]) {
      const qaAssetsIds = this.plushieQaAsset[plushieId];

      qaAssetsIds.forEach((id) => {
        qaAssets.push(this.qaAsset[id]);
      });

      return qaAssets;
    }

    qaAssets = await DIContainer.QaAssetRepository.getByPlushieId(plushieId);

    this.updateQaAssets({ plushieId, qaAssets: qaAssets });

    return qaAssets;
  }

  @Action({ rawError: true })
  async loadQaAssetById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<QaAsset | undefined> {
    if (useCache && this.qaAsset[id]) {
      return this.qaAsset[id];
    }

    const item = await DIContainer.QaAssetRepository.getById(id);

    if (!item) {
      return undefined;
    }

    this.updateQaAsset(item);

    return item;
  }

  @Action({ rawError: true })
  async rotatePhoto({
    item,
    rotationDegrees,
  }: {
    item: QaAsset;
    rotationDegrees: number;
  }): Promise<void> {
    await DIContainer.QaPhotoRotationService.rotatePhoto(
      item.id,
      rotationDegrees
    );

    await this.context.dispatch("onImageRotated", { itemId: item.storageItem });
  }

  // ################################### REJECTION REASONS #########################################

  get rejectionReasonsList(): RejectionReason[] {
    const list: RejectionReason[] = [];

    Object.keys(this.rejectionReason).forEach((id) => {
      list.push(this.rejectionReason[id]);
    });

    return list;
  }

  get getRejectionReasonById(): (id: string) => RejectionReason | undefined {
    return (id: string) => this.rejectionReason[id];
  }

  get getRejectionReasonsByIds(): (ids: string[]) => RejectionReason[] {
    return (ids: string[]) => {
      const result: RejectionReason[] = [];

      ids.forEach((id) => {
        if (this.rejectionReason[id] !== undefined) {
          result.push(this.rejectionReason[id]);
        }
      });

      return result;
    };
  }

  @Mutation
  updateRejectionReason(payload: RejectionReason): void {
    Vue.set(this.rejectionReason, payload.id, payload);
  }

  @Mutation
  removeRejectionReason(id: string): void {
    delete this.rejectionReason[id];
  }

  @Action({ rawError: true })
  async deleteRejectionReasonById(id: string): Promise<void> {
    await DIContainer.RejectionReasonRepository.deleteById(id);

    this.removeRejectionReason(id);

    return;
  }

  @Action({ rawError: true })
  async loadRejectionReasons({
    page = 1,
    limit = 20,
    filter,
    order,
  }: {
    page?: number;
    limit?: number;
    filter?: Dictionary<ApiFilterValue>;
    order?: QueryOrderParameter;
  }): Promise<ResourceCollection<RejectionReason>> {
    const collection = await DIContainer.RejectionReasonRepository.getList(
      page,
      limit,
      filter,
      order
    );

    collection.getItems().forEach((item) => {
      this.updateRejectionReason(item);
    });

    return collection;
  }

  @Action({ rawError: true })
  async loadRejectionReasonById(
    id: string
  ): Promise<RejectionReason | undefined> {
    if (this.rejectionReason[id] !== undefined) {
      return this.rejectionReason[id];
    }

    const rejectionReason = await DIContainer.RejectionReasonRepository.getById(
      id
    );

    if (rejectionReason) {
      this.updateRejectionReason(rejectionReason);
    }

    return rejectionReason;
  }

  @Action({ rawError: true })
  async saveRejectionReason(
    rejectionReason: RejectionReason
  ): Promise<RejectionReason> {
    const item = await DIContainer.RejectionReasonRepository.save(
      rejectionReason
    );

    this.updateRejectionReason(item);

    return item;
  }

  @Action({ rawError: true })
  async loadRejectionReasonsByIds(
    ids: string[]
  ): Promise<Dictionary<RejectionReason | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<RejectionReason | undefined> = {};

    ids.forEach((id) => {
      if (!this.rejectionReason[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.rejectionReason[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.RejectionReasonRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updateRejectionReason(item);
    });

    return { ...result, ...items };
  }

  // ################################### REJECTION REASONS TYPES #########################################

  get rejectionReasonTypes(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.rejectionReasonType).forEach((id) => {
      list.push(this.rejectionReasonType[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get rejectionReasonTypesDictionary(): Dictionary<DirectoryValue> {
    return { ...this.rejectionReasonType };
  }

  get getRejectionReasonTypeById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.rejectionReasonType[id];
  }

  @Mutation
  updateRejectionReasonType(payload: DirectoryValue): void {
    Vue.set(this.rejectionReasonType, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadRejectionReasonTypes(): Promise<DirectoryValue[]> {
    let items: DirectoryValue[];

    if (Object.keys(this.rejectionReasonType).length === 0) {
      const collection = await DIContainer.RejectionReasonTypeRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateRejectionReasonType(item);
      });
    }

    items = [];

    Object.keys(this.rejectionReasonType).forEach((key) => {
      items.push(this.rejectionReasonType[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadRejectionReasonTypeById(
    id: string
  ): Promise<DirectoryValue | undefined> {
    await this.loadRejectionReasonTypes();

    return this.getRejectionReasonTypeById(id);
  }

  // ################################### REJECTION REASONS PRODUCT RELATIONS #########################################
  get getRejectionReasonProductRelationsByReasonId(): (
    reasonId: string
  ) => RejectionReasonProductRelation[] {
    return (reasonId: string) => {
      if (!this.reasonRelations[reasonId]) {
        return [];
      }

      const relations: RejectionReasonProductRelation[] = [];
      this.reasonRelations[reasonId].forEach((relationId) => {
        relations.push(this.rejectionReasonProductRelation[relationId]);
      });

      return relations;
    };
  }

  get getRejectionReasonProductRelationsByProductId(): (
    productId: string
  ) => RejectionReasonProductRelation[] {
    return (productId: string) => {
      if (!this.productRelations[productId]) {
        return [];
      }

      const relations: RejectionReasonProductRelation[] = [];
      this.productRelations[productId].forEach((relationId) => {
        relations.push(this.rejectionReasonProductRelation[relationId]);
      });

      return relations;
    };
  }

  @Mutation
  updateRejectionReasonProductRelations({
    productId,
    relations,
  }: {
    productId: string;
    relations: RejectionReasonProductRelation[];
  }): void {
    const relationIds: string[] = [];
    relations.forEach((relation) => {
      Vue.set(this.rejectionReasonProductRelation, relation.id, relation);

      relationIds.push(relation.id);

      if (this.reasonRelations[relation.rejectionReason] === undefined) {
        return;
      }

      if (
        !this.reasonRelations[relation.rejectionReason].includes(relation.id)
      ) {
        this.reasonRelations[relation.rejectionReason].push(relation.id);
      }
    });

    Vue.set(this.productRelations, productId, relationIds);
  }

  @Mutation
  updateRejectionReasonReasonRelations({
    reasonId,
    relations,
  }: {
    reasonId: string;
    relations: RejectionReasonProductRelation[];
  }): void {
    const relationIds: string[] = [];
    relations.forEach((relation) => {
      Vue.set(this.rejectionReasonProductRelation, relation.id, relation);
      relationIds.push(relation.id);

      if (this.productRelations[relation.product] === undefined) {
        return;
      }

      if (!this.productRelations[relation.product].includes(relation.id)) {
        this.productRelations[relation.product].push(relation.id);
      }
    });

    Vue.set(this.reasonRelations, reasonId, relationIds);
  }

  @Action({ rawError: true })
  async loadRejectionReasonProductRelationsByProductId({
    productId,
    useCache = true,
  }: {
    productId: string;
    useCache?: boolean;
  }): Promise<RejectionReasonProductRelation[]> {
    if (useCache && this.productRelations[productId]) {
      const relationIds = this.productRelations[productId];

      const result: RejectionReasonProductRelation[] = [];
      relationIds.forEach((id) => {
        result.push(this.rejectionReasonProductRelation[id]);
      });

      return result;
    }

    const collection = await DIContainer.RejectionReasonProductRelationRepository.getByProductId(
      productId
    );

    const relations = collection.getItems();

    this.updateRejectionReasonProductRelations({ productId, relations });

    return relations;
  }

  @Action({ rawError: true })
  async loadRejectionReasonProductRelationsByReasonIds({
    reasonIds,
    useCache = true,
  }: {
    reasonIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<string[] | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<string[] | undefined> = {};

    reasonIds.forEach((reasonId) => {
      if (useCache && this.reasonRelations[reasonId]) {
        result[reasonId] = this.reasonRelations[reasonId];
        return;
      }

      missing.push(reasonId);
    });

    if (!missing.length) {
      return result;
    }

    const collection = await DIContainer.RejectionReasonProductRelationRepository.getByReasonIds(
      missing
    );

    const items = collection.getItems();

    const relations: Dictionary<RejectionReasonProductRelation[]> = {};

    items.forEach((item) => {
      if (!relations[item.rejectionReason]) {
        relations[item.rejectionReason] = [];
      }
      relations[item.rejectionReason].push(item);
    });

    for (const reasonId in relations) {
      const reasonRelations = relations[reasonId];

      if (!reasonRelations) {
        continue;
      }

      result[reasonId] = reasonRelations.map((item) => item.id);

      this.updateRejectionReasonReasonRelations({
        reasonId,
        relations: reasonRelations,
      });
    }

    return result;
  }

  // ################################### QA DECISION #########################################

  get getPlushieLastRejection(): (plushieId: string) => QaDecision | undefined {
    return (plushieId: string) => {
      const rejectionId = this.plushieLastRejection[plushieId];

      if (!rejectionId) {
        return undefined;
      }

      return this.rejection[rejectionId];
    };
  }

  @Mutation
  updatePlushieLastRejection(rejection: QaDecision): void {
    Vue.set(this.rejection, rejection.id, rejection);

    Vue.set(this.plushieLastRejection, rejection.plushie, rejection.id);
  }

  @Mutation
  removePlushieLastRejection(plushieId: string): void {
    Vue.delete(this.plushieLastRejection, plushieId);
  }

  @Action({ rawError: true })
  async approvePlushie(plushieId: string): Promise<void> {
    this.context.commit("markPlushieAsProcessing", plushieId);

    try {
      await DIContainer.QaDecisionRepository.approvePlushie(plushieId);

      await this.context.dispatch("refreshPlushie", plushieId);
    } finally {
      this.context.commit("removePlushieProcessingMark", plushieId);
    }
  }

  @Action({ rawError: true })
  async rejectPlushie({
    plushieId,
    rejectionReasonIds,
    comment,
  }: {
    plushieId: string;
    rejectionReasonIds: string[];
    comment: string;
  }): Promise<void> {
    this.context.commit("markPlushieAsProcessing", plushieId);

    try {
      await DIContainer.QaDecisionRepository.rejectPlushie(
        plushieId,
        rejectionReasonIds,
        comment
      );

      await this.context.dispatch("refreshPlushie", plushieId);
    } finally {
      this.context.commit("removePlushieProcessingMark", plushieId);
    }
  }

  @Action({ rawError: true })
  async loadPlushieLastRejection({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<QaDecision | undefined> {
    if (useCache && this.plushieLastRejection[plushieId]) {
      const rejectionId = this.plushieLastRejection[plushieId];

      return this.rejection[rejectionId];
    }

    const rejection = await DIContainer.QaDecisionRepository.getPlushieLastRejection(
      plushieId
    );

    if (rejection) {
      this.updatePlushieLastRejection(rejection);
    } else {
      this.removePlushieLastRejection(plushieId);
    }

    return rejection;
  }

  // ################################### QA REJECTION DETAILS #########################################

  get getRejectionDetailsById(): (
    id: string
  ) => QaRejectionDetails | undefined {
    return (id: string) => {
      return this.rejectionDetails[id] as QaRejectionDetails | undefined;
    };
  }

  @Mutation
  updateRejectionDetails(rejectionDetails: QaRejectionDetails): void {
    Vue.set(this.rejectionDetails, rejectionDetails.id, rejectionDetails);
  }

  @Action({ rawError: true })
  async loadRejectionDetailsById(
    id: string
  ): Promise<QaRejectionDetails | undefined> {
    const rejectionDetails = await DIContainer.QaRejectionDetailsRepository.getById(
      id
    );

    if (!rejectionDetails) {
      return;
    }

    this.updateRejectionDetails(rejectionDetails);
  }

  // ################################### EVENTS #########################################

  @Action({ rawError: true })
  async onPlushieUpdated(plushieId: string): Promise<void> {
    if (this.plushieLastRejection[plushieId]) {
      await this.loadPlushieLastRejection({
        plushieId,
        useCache: false,
      });
    }

    return;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
