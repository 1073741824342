import ApiService from "@/modules/api/api.service";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import BasicRepository from "@/modules/api/basic.repository";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import { Dictionary } from "@/lib/Dictionary.type";

import Upgrade from "./upgrade.model";

export default class UpgradeRepository extends BasicRepository<Upgrade> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Upgrade>,
    responseParser: ApiCollectionResponseParserService<Upgrade>
  ) {
    super("plushie_upgrades", api, objectBuilder, responseParser);
  }

  protected prepareData(item: Upgrade): Dictionary<string | number | boolean> {
    const data: Dictionary<string | number | boolean> = {
      id: item.id,
      code: item.code,
      displayToFactory: item.displayToFactory,
      title: item.name,
      isManageable: item.isManageable,
    };

    if (item.shortName) {
      data.shortName = item.shortName;
    }

    return data;
  }
}
