












import Vue from "vue";
import { Component } from "vue-property-decorator";

import UpgradeManageForm from "./ManageForm.vue";

@Component({
  metaInfo() {
    return {
      title: "Add Upgrade",
    };
  },
  components: {
    UpgradeManageForm,
  },
})
export default class UpgradeAddPage extends Vue {
  public onCompleted(): void {
    void this.$router.push({ name: "UpgradesListPage" });
  }
}
