import { CommandCode } from "./command-code";

export default class Command {
  private readonly fExcludedBasicCommandCodes = [
    CommandCode.ApproveCustomerPreview,
    CommandCode.ApproveQualityInspection,
    CommandCode.CancelPlushie,
    CommandCode.ConfirmBulkShipment,
    CommandCode.MoveToBulkInspection,
    CommandCode.MoveToPpsInspection,
    CommandCode.MoveToQualityInspection,
    CommandCode.PrepareToProduction,
    CommandCode.RejectQualityInspection,
    CommandCode.ReturnToCustomerPreviewApproved,
    CommandCode.ShipToMainFacility,
    CommandCode.UndoCustomerPreviewApprove,
  ];

  constructor(
    private fCode: CommandCode,
    private fCommandName: string,
    private fRoutePath: string,
    private fIsBasic: boolean
  ) {
    [fCode, fCommandName, fRoutePath, fIsBasic].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get code(): CommandCode {
    return this.fCode;
  }

  get commandName(): string {
    return this.fCommandName;
  }

  get routePath(): string {
    return this.fRoutePath;
  }

  get isBasic(): boolean {
    if (this.fExcludedBasicCommandCodes.includes(this.code)) {
      return false;
    }

    return this.fIsBasic;
  }
}
