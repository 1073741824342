












import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import UpgradeManageForm from "./ManageForm.vue";

@Component({
  metaInfo() {
    return {
      title: "Edit Upgrade",
    };
  },
  components: {
    UpgradeManageForm,
  },
})
export default class UpgradeEditPage extends Vue {
  @Prop({ required: true })
  public readonly upgradeId!: string;

  @Prop()
  public readonly listQuery?: Record<string, any>;

  public onCompleted(): void {
    void this.$router.push({
      name: "UpgradesListPage",
      query: this.listQuery || {},
    });
  }
}
